// src/screens/BuyerHome.tsx
import React from 'react';
import '../../global.css';

const BuyerHome: React.FC = () => {
  return (
    <div className="stack-container">
      <div className="header">
        <h1 className="text-center">Welcome to Your Buyer Home</h1>
        <p className="text-center">Browse, request, and manage your car preferences easily.</p>
      </div>
      <div className="space">
        <p>Explore our selection of cars or view your previous requests.</p>
      </div>
    </div>
  );
};

export default BuyerHome;
