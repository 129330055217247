// src/api/apiClient.tsx
import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_URL,  // Base URL
  headers: {
    'Content-Type': 'application/json',
    // Optionally include Authorization token if needed
  },
});

// Request interceptor for adding Authorization token (if needed)
apiClient.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('userToken');  // Use localStorage instead of AsyncStorage
  // If token exists, add Authorization header
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;

}, (error) => {
  // If the request fails, we return the error as-is
  return Promise.reject(error);
});

// Response interceptor to always return the response (even in case of errors)
apiClient.interceptors.response.use(
  (response) => {
    // Just return the response for successful requests (status 2xx)
    return response;
  },
  (error) => {
    // For errors (status 4xx/5xx), return the response directly
    return error.response || error;  // Return the full response in case of error
  }
);

export default apiClient;
