import React from 'react';

interface InputProps {
  placeholder: string;
  value: string;
  onChangeText: (text: string) => void;
  placeholderTextColor?: string;
  secureTextEntry?: boolean;
  style?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  maxLength?: number;
  keyboardType?: 'text' | 'email' | 'number' | 'tel' | 'password';
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters';
  autoFocus?: boolean;
  disabled?: boolean;
}

const Input: React.FC<InputProps> = ({
  placeholder,
  value,
  onChangeText,
  placeholderTextColor = '#aaa',
  secureTextEntry = false,
  style,
  inputStyle,
  maxLength,
  keyboardType = 'text',
  autoCapitalize = 'sentences',
  autoFocus = false,
  disabled = false,
}) => {
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        marginBottom: '1rem',
        ...(style || {}),
      }}
    >
      <input
        type={secureTextEntry ? 'password' : 'text'}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChangeText(e.target.value)}
        maxLength={maxLength}
        autoCapitalize={autoCapitalize}
        autoFocus={autoFocus}
        disabled={disabled}
        style={{
          width: '100%',
          padding: '10px 15px',
          paddingRight: '40px',
          fontSize: '16px',
          border: '1px solid #DDD',
          borderRadius: '8px',
          outline: 'none',
          boxSizing: 'border-box',
          color: '#333',
          backgroundColor: disabled ? "#DDD" : "#FFF",
          ...(inputStyle || {}),
        }}
      />
      <style>
        {`
          input::placeholder {
            color: ${placeholderTextColor};
          }
        `}
      </style>
    </div>
  );
};

export default Input;
