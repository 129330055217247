import apiClient from './apiClient';

const carsBaseRoute = "/car-data";

export const CarData = {
    getMakes: async (): Promise<any> => {
        try {
            return await apiClient.get(`${carsBaseRoute}/makes`);
        } catch (error) {
            return error;
        }
    },
    getModels: async (make: string): Promise<any> => {
        try {
            return await apiClient.get(`${carsBaseRoute}/models/${make}`);
        } catch (error) {
            return error;
        }
    },
};
