import React, { useState } from 'react';
import Modal from './Modal';
import '../../global.css';
import { PasswordResetAPI } from '../../api/passwordReset';
import Input from '../ui/inputs/Input';
import { toast } from 'react-toastify';

interface ForgotPasswordModalProps {
    accountType: string,
    isOpen: boolean;
    onClose: () => void;
    onResetPassword: (email: string) => void;
}

const ForgotPasswordModal: React.FC<ForgotPasswordModalProps> = ({ accountType, isOpen, onClose, onResetPassword }) => {
    const [email, setEmail] = useState('');
    const [showRequestPassword, setShowRequestPassword] = useState(true);
    const [showVerifyCode, setShowVerifyCode] = useState(false);
    const [code, setCode] = useState("");
    const [error, setError] = useState('');

    const handleRequestCode = async () => {
        if (email) {
            const requestPasswordRes = await PasswordResetAPI.requestPassword(email, accountType);
            if (requestPasswordRes.data.success) {
                // onResetPassword(email);
                toast.success(requestPasswordRes.data.message);
                setShowRequestPassword(false);
                setShowVerifyCode(true);
            } else {
                // alert(requestPasswordRes.data.message);
                toast.error(requestPasswordRes.data.message);
            }
        } else {
            // alert('Validation Error, Please enter your email address.');
            toast.error("Validation Error, Please enter your email address.");
        }
    };

    const handleResetPassword = async () => {
        const resetPasswordRes = await PasswordResetAPI.generatePassword(email, accountType, code);
        if (resetPasswordRes.success) {
            // alert(resetPasswordRes.message);
            toast.success(resetPasswordRes.message);
            setShowRequestPassword(false);
            setShowVerifyCode(false);
            setEmail('');
        } else {
            // alert(resetPasswordRes.message);
            toast.error(resetPasswordRes.message);
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <div className="stack-container white">
                <h2 className="text-center">Forgot Password</h2>

                {showRequestPassword && <div className="space">
                    <Input
                        placeholder={'Email'}
                        value={email}
                        onChangeText={setEmail}
                    />
                    <button
                        onClick={handleRequestCode}
                        className="button wide"
                    >
                        REQUEST CODE
                    </button>
                </div>
                }
                {
                    showVerifyCode && <div>
                        <Input
                            placeholder="Enter verification code"
                            value={code}
                            onChangeText={setCode}

                        />
                        <button
                            onClick={handleResetPassword}
                            className="button wide"
                        >
                            RESET PASSWORD
                        </button>
                    </div>
                }
            </div>
        </Modal>
    );
};

export default ForgotPasswordModal;
