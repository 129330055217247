import React, { useState, useEffect, useRef } from 'react';

interface DropdownProps {
  items: string[];
  onSelect: (option: string) => void;
  title?: string;
}

const Dropdown: React.FC<DropdownProps> = ({ items, onSelect, title = "Select an option" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(title);
  const [filteredItems, setFilteredItems] = useState(items);
  const [searchQuery, setSearchQuery] = useState('');
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setFilteredItems(
      items.filter((item) =>
        item.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );

    if (!items.includes(selectedOption)) {
      setSelectedOption(title);
    }
  }, [items, searchQuery, selectedOption, title]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    onSelect(option);
    setIsOpen(false);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setSearchQuery(query);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} style={{ position: 'relative', display: 'inline-flex', flexDirection: 'column' }}>
      <button
        onClick={toggleDropdown}
        style={{
          padding: '10px',
          backgroundColor: '#222',
          color: "#FFF",
          border: '1px solid #ccc',
          borderRadius: 6,
          cursor: 'pointer',
          textAlign: 'center',
          width: 150,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {selectedOption}
        <span
          style={{
            display: 'inline-block',
            width: '0',
            height: '0',
            borderLeft: '5px solid transparent',
            borderRight: '5px solid transparent',
            borderTop: '5px solid #EEE',
          }}
        />
      </button>

      {isOpen && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            width: '100%',
            backgroundColor: 'white',
            border: '1px solid #ccc',
            zIndex: 9999,
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            maxHeight: '200px',
            overflowY: 'auto',
            borderRadius: 6,
          }}
        >
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
            style={{
              backgroundColor: "#111",
              color: "#FFF",
              padding: '10px',
              width: '100%',
              boxSizing: 'border-box',
              border: 'none',
              borderBottom: '1px solid #ccc',
            }}
          />
          <ul
            style={{
              listStyle: 'none',
              padding: 0,
              margin: 0,
              backgroundColor: "#333",
              color: "#FFF",
            }}
          >
            {filteredItems.map((item, index) => (
              <li
                key={index}
                onClick={() => handleOptionClick(item)}
                style={{
                  padding: '10px',
                  cursor: 'pointer',
                  transition: 'background-color 0.2s ease-in-out',
                  textAlign: 'center',
                  backgroundColor: "#333",
                  color: "#FFF"
                }}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#000')}
                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '')}
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
