import React from 'react';

const TermsOfService: React.FC = () => {
    return (
        <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
            <h1>Terms of Service</h1>
            <p>
                By using our services, you agree to the following Terms of Service. Please read them carefully. If you do not agree to these terms, you should not use our services.
            </p>
            <h2>1. Acceptance of Terms</h2>
            <p>
                By accessing or using our services, you agree to comply with these Terms of Service, our Privacy Policy, and all applicable laws.
            </p>
            <h2>2. User Responsibilities</h2>
            <p>
                You are responsible for all activity that occurs under your account. You agree to use our services in accordance with all applicable laws and not to engage in any unlawful activities.
            </p>
            <h2>3. Service Availability</h2>
            <p>
                We strive to provide uninterrupted services; however, we do not guarantee that our services will always be available. We reserve the right to modify, suspend, or discontinue the services at any time.
            </p>
            <h2>4. Payments and Fees</h2>
            <p>
                Certain services may require payment. By using such services, you agree to pay the fees associated with those services. All payments are processed securely and are subject to the applicable payment terms.
            </p>
            <h2>5. Intellectual Property</h2>
            <p>
                The content provided on our website, including text, images, logos, and trademarks, is protected by intellectual property laws. You may not use our content without prior written consent.
            </p>
            <h2>6. Limitation of Liability</h2>
            <p>
                Our liability is limited to the maximum extent permitted by law. We are not responsible for any damages resulting from your use or inability to use our services.
            </p>
            <h2>7. Termination</h2>
            <p>
                We may suspend or terminate your access to our services at any time if we believe you have violated these terms. You may terminate your account by contacting us.
            </p>
            <h2>8. Changes to Terms of Service</h2>
            <p>
                We reserve the right to modify these Terms of Service at any time. Any changes will be posted on this page with the updated effective date.
            </p>
            <p>If you have any questions about these Terms of Service, please contact us.</p>
        </div>
    );
};

export default TermsOfService;
