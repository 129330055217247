// src/utils/ColorPalettes.ts

type ThemeType = 'success' | 'danger' | 'warning' | 'info' | 'theme1' | 'theme2' | 'theme3';

const ColorPalettes = {
  success: {
    backgroundColor: '#28A745',
    textColor: '#FFFFFF',
    borderColor: "transparent"
  },
  danger: {
    backgroundColor: '#DC3545',
    textColor: '#FFFFFF',
    borderColor: "transparent"
  },
  warning: {
    backgroundColor: '#f39c12',
    textColor: '#FFFFFF',
    borderColor: "transparent"
  },
  link: {
    backgroundColor: '#FFF',
    textColor: 'gray',
    borderColor: "transparent"
  },
  info: {
    backgroundColor: '#4488FF',
    textColor: '#FFFFFF',
    borderColor: "transparent"
  },
  theme1: {
    backgroundColor: '#FFFFFF',
    textColor: '#222',
    borderColor: "#000"
  },
  theme2: {
    backgroundColor: '#222',
    textColor: '#FFFF',
    borderColor: "transparent"
  },
  theme3: {
    backgroundColor: '#1b98e0',
    textColor: '#FFF',
    borderColor: "transparent"
  },
};

const getColorPalette = (theme: ThemeType) => {
  return ColorPalettes[theme] || ColorPalettes.info;
};

export default getColorPalette;
