import React, { useEffect, useRef, useState } from "react";
import Input from "../../components/ui/inputs/Input";
import { Account } from "../../api/account";
import { useSellerAuth } from "../../contexts/seller/SellerAuthContext";
import { Autocomplete } from "@react-google-maps/api";
// import resizeImage from "../../utils/resizeImage";
import '../../global.css';
import { toast } from "react-toastify";

const SellerAccountScreen = () => {
  const [sellerInfo, setSellerInfo] = useState<any>(null);
  const [email, setEmail] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [phone, setPhone] = useState("");
  const [profilePicture, setProfilePicture] = useState<string | null>(null);
  const [passwordForDeleteAccount, setPasswordForDeleteAccount] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const { logoutSeller, userData, updateSellerData } = useSellerAuth();

  const [address, setAddress] = useState({
    streetAddress: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
    lng: 0,
    lat: 0
  });
  const [isAddressValid, setIsAddressValid] = useState(false);
  const autocompleteRef: any = useRef(null);

  const extractAddressDetails = (place: any) => {
    let address = {
      streetAddress: "",
      city: "",
      state: "",
      zipcode: "",
      country: "",
      lng: 0,
      lat: 0
    };

    if (place.address_components) {
      place.address_components.forEach((component: any) => {
        const types = component.types;
        if (types.includes("street_number")) {
          address.streetAddress = component.long_name + " ";
        }
        if (types.includes("route")) {
          address.streetAddress += component.long_name;
        }
        if (types.includes("locality")) {
          address.city = component.long_name;
        }
        if (types.includes("administrative_area_level_1")) {
          address.state = component.long_name;
        }
        if (types.includes("postal_code")) {
          address.zipcode = component.long_name;
        }
        if (types.includes("country")) {
          address.country = component.long_name;
        }
      });
    }
    return address;
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    if (name === "businessAddress") {
      setAddress((prevState: any) => ({
        ...prevState,
        streetAddress: value,
      }));
      setIsAddressValid(false);
      return;
    }
  };


  const onPlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();

      if (place && place.address_components) {
        const structuredAddress = extractAddressDetails(place);

        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();

        const addressWithGeometry = {
          ...structuredAddress,
          lat,
          lng,
        };
        setAddress(addressWithGeometry);
        setIsAddressValid(true);
      }
    }
  };

  useEffect(() => {
    async function getSellerInfo() {
      const sellerInfoRes = await Account.getSellerInfo();
      let seller = null;
      if (sellerInfoRes?.data?.seller) {
        seller = sellerInfoRes?.data?.seller;
        setEmail(seller.email);
        setBusinessName(seller.businessName);
        setPhone(seller.phone);
        setSellerInfo(seller);

        if (seller?.profileImage?.base64) {
          setProfilePicture(seller.profileImage.base64);
        }
      }
    }
    getSellerInfo();
  }, [userData]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result as string;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          if (ctx) {
            canvas.width = img.width;
            canvas.height = img.height;

            ctx.drawImage(img, 0, 0);

            const jpegBase64 = canvas.toDataURL("image/jpeg", 0.9);

            const base64Data = jpegBase64.split(',')[1];
            setProfilePicture(base64Data);
          }
        };
      };

      reader.readAsDataURL(file);
    }
  };

  const handleSaveChanges = async () => {
    if (!address.zipcode) {
      toast.error("Address is not valid");
      return;
    }
    setIsProcessing(true);
    const updateSellerInfoRes = await Account.updateSellerInfo(
      businessName,
      phone,
      address
    );
    if (updateSellerInfoRes?.data?.success) {
      // alert(updateSellerInfoRes?.data?.message);
      updateSellerData();
      toast.success(updateSellerInfoRes?.data?.message);
    } else {
      // alert(updateSellerInfoRes?.data?.message);
      toast.error(updateSellerInfoRes?.data?.message);
    }
    setIsProcessing(false);
  };

  const handleDisableAccount = async () => {
    if (window.confirm("Are you sure you want to deactivate your account?")) {
      const disableSellerRes = await Account.disableSellerAccount();
      if (disableSellerRes?.data?.success) {
        await logoutSeller();
      } else {
        console.log("error disabling account", disableSellerRes?.data.message);
      }
      setIsProcessing(false);
    }
  };

  const handleDeleteAccount = async () => {
    if (!passwordForDeleteAccount.length) {
      // alert("Password is required for this action ...");
      toast.error("Password is required for this action");
      return;
    }

    if (window.confirm("Are you sure you want to delete your account?")) {
      setIsProcessing(true);
      const deleteAccountRes = await Account.deleteSellerAccount(passwordForDeleteAccount);
      if (deleteAccountRes?.data?.success) {
        await logoutSeller();
      } else {
        console.log("delete account res", deleteAccountRes?.data?.message);
      }
      setIsProcessing(false);
    }
  };

  return (
    <div className="stack-container" style={{ marginBottom: 50 }}>
      {/* GENERAL INFO SECTION */}
      <div className="space">
        <h2 className="text-center text-shadow">General Info</h2>
        <Input
          placeholder="Email ..."
          value={email}
          disabled
          onChangeText={() => { }}
        />
        <Input
          placeholder="Business name ..."
          value={businessName}
          onChangeText={setBusinessName}
        />
        <Input
          placeholder="Phone ..."
          value={phone}
          onChangeText={setPhone}
        />

        <div className="space">
          <Autocomplete
            onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
            onPlaceChanged={onPlaceChanged}
          >
            <input
              type="text"
              name="businessAddress"
              placeholder={sellerInfo?.businessAddress?.streetAddress ? sellerInfo.businessAddress.streetAddress : "Enter business address..."}
              value={address.streetAddress}
              onChange={handleInputChange}
              required
              style={{ width: 300, height: 30, borderRadius: 6, borderWidth: 1, borderColor: "#DDD" }}
            />
          </Autocomplete>
        </div>

        <button
          className="button wide"
          onClick={handleSaveChanges}
          disabled={isProcessing}
        >
          SAVE
        </button>
      </div>

      <div className="space stack-container">
        <label htmlFor="file-upload" className="upload-button">
          {profilePicture ? (
            <img
              src={`data:image/png;base64,${profilePicture}`}
              alt="Profile"
              className="profile-image"
              style={{ width: 100, height: 100, marginTop: 20, marginBottom: 20 }}
            />
          ) : (
            "Upload Profile Picture"
          )}
        </label>
        <input
          type="file"
          id="file-upload"
          onChange={handleFileChange}
          style={{ display: "none" }}
          accept="image/*"
        />
        <button
          className="button wide"

          onClick={async () => {
            const updateImageRes = await Account.updateSellerProfileImage(profilePicture);
            if (updateImageRes?.data?.success) {
              // alert(updateImageRes?.data?.message);
              toast.success(updateImageRes?.data?.message);
            } else {
              // alert(updateImageRes?.data?.message);
              toast.error(updateImageRes?.data?.message);
            }
          }}
          disabled={isProcessing}
          style={{ marginTop: 10, marginBottom: 10 }}
        >
          SAVE IMAGE
        </button>
      </div>

      {/* CHANGE PASSWORD SECTION */}
      <div className="space">
        <h2 className="text-center text-shadow">Change Password</h2>
        <Input
          placeholder="Current password"
          value={currentPassword}
          onChangeText={setCurrentPassword}
          secureTextEntry={true}
        />
        <Input
          placeholder="New password"
          value={newPassword}
          onChangeText={setNewPassword}
          secureTextEntry={true}
        />
        <Input
          placeholder="Confirm password"
          value={confirmedPassword}
          onChangeText={setConfirmedPassword}
          secureTextEntry={true}
        />
        <button
          className="button wide"
          onClick={async () => {
            setIsProcessing(true);
            const changePasswordRes = await Account.changeSellerPassword(currentPassword, newPassword);
            if (changePasswordRes?.data?.success) {
              // alert(changePasswordRes?.data.message);
              toast.success(changePasswordRes?.data?.message);
            } else {
              // alert(changePasswordRes?.data?.message);
              toast.error(changePasswordRes?.data?.message);
            }
            setIsProcessing(false);
          }}
          disabled={isProcessing}
        >
          CHANGE PASSWORD
        </button>
      </div>

      {/* DISABLE ACCOUNT SECTION */}
      <div className="space">
        <h2 className="text-center text-shadow">Disable Account</h2>
        <button
          title="DISABLE MY ACCOUNT"
          onClick={handleDisableAccount}
          disabled={isProcessing}
          className="button wide clean text-warning bold"
        >
          DISABLE MY ACCOUNT
        </button>
      </div>

      {/* DELETE ACCOUNT SECTION */}
      <div className="space">
        <h2 className="text-center text-shadow">Delete Account</h2>
        <Input
          placeholder="Enter password for deletion"
          value={passwordForDeleteAccount}
          onChangeText={setPasswordForDeleteAccount}
          secureTextEntry={true}
        />
        <button
          title="DELETE MY ACCOUNT"
          onClick={handleDeleteAccount}
          disabled={isProcessing}
          className="button wide clean text-danger bold"
        >
          DELETE MY ACCOUNT
        </button>
      </div>

      {/* LOG OUT BUTTON */}
      <div className="space">
        <button
          onClick={async () => {
            await logoutSeller();
          }}
          className="button clean"
        >
          LOG OUT
        </button>
      </div>
    </div>
  );
};

export default SellerAccountScreen;
