import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Tabs from '../tabs/Tabs';
import { useSellerAuth } from '../../contexts/seller/SellerAuthContext';
import SellerConfigScreen from '../../pages/seller/SellerConfig';
import SellerAccountScreen from '../../pages/seller/SellerAccountDetails';
import SellerHome from '../../pages/seller/SellerHome';

const SellerHeader: React.FC = () => {
    const [activeTab, setActiveTab] = useState("seller-home");
    const { userData, logoutSeller } = useSellerAuth();
    const navigate = useNavigate();

    const tabs = [
        { label: "HOME", value: "seller-home" },
        { label: 'LEAD FILTERS', value: 'lead-filters' },
        { label: 'ACCOUNT', value: 'account-details' },
        // { label: "LOG OUT", value: "log-out" }
    ];

    return (
        <>
            <header
                className="row-container"
                style={{
                    backgroundColor: "#222",
                    // marginTop: 20,
                    marginBottom: 1,
                    borderBottom: "1px solid #EEE", // Same border-bottom style as BuyerHeader
                    padding: "10px 0",
                    width: "100%",
                    textAlign: "center",
                    height: 60
                }}
            >
                <div>
                    <Tabs
                        tabs={tabs}
                        onTabClick={async (tab: any) => {
                            setActiveTab(tab);
                            if (tab === "log-out") {
                                await logoutSeller();
                                navigate("/");
                            }
                        }} />
                </div>
            </header>

            <div>
                {activeTab === 'seller-home' && <SellerHome />}
                {activeTab === 'lead-filters' && <SellerConfigScreen />}
                {activeTab === 'account-details' && <SellerAccountScreen />}
            </div>
        </>
    );
};

export default SellerHeader;
