import React, { useEffect, useState } from "react";
import "../../global.css";
import { SellerWhitelist } from "../../api/sellerWhiteList";
import { toast } from "react-toastify";
import Dropdown from "../../components/ui/dropdown/Dropdown";
import { SellerBlacklist } from "../../api/sellerBlackList";
import { CarData } from "../../api/carData";
import DeleteIcon from "../../components/icons/DeleteIcon";

interface CarItem {
  make: string;
  model: string;
  id: string;
}

const SellerConfigScreen = () => {
  const [carMakes, setCarMakes] = useState<string[]>([]);
  const [carWhiteModels, setCarWhiteModels] = useState<string[]>([]);
  const [carBlackModels, setCarBlackModels] = useState<string[]>([]);
  const [whiteList, setWhiteList] = useState<CarItem[]>([]);
  const [blackList, setBlackList] = useState<CarItem[]>([]);
  const [selectedWhiteMake, setSelectedWhiteMake] = useState<string>("");
  const [selectedWhiteModel, setSelectedWhiteModel] = useState<string>("");
  const [selectedBlackMake, setSelectedBlackMake] = useState<string>("");
  const [selectedBlackModel, setSelectedBlackModel] = useState<string>("");
  const [triggerFetch, setTriggerFetch] = useState(false);

  const fetchCarMakes = async (): Promise<void> => {
    try {
      const carMakesRes = await CarData.getMakes();
      if (carMakesRes?.data?.makes) {
        setCarMakes(carMakesRes.data.makes.map((car: any) => car.MakeName));
      } else {
        setCarMakes([]);
      }
    } catch (error) {
      console.error("Error fetching car makes", error);
      setCarMakes([]);
    }
  };

  const fetchWhiteCarModels = async (): Promise<void> => {
    try {
      const carModelsRes = await CarData.getModels(selectedWhiteMake);
      if (carModelsRes?.data?.success) {
        setCarWhiteModels(carModelsRes.data.models.map((car: any) => car.Model_Name));
      } else {
        setCarWhiteModels([]);
      }
    } catch (error) {
      console.error("Error fetching car models", error);
      setCarWhiteModels([]);
    }
  };

  const fetchBlackCarModels = async (): Promise<void> => {
    try {
      const carModelsRes = await CarData.getModels(selectedBlackMake);
      if (carModelsRes?.data?.success) {
        setCarBlackModels(carModelsRes.data.models.map((car: any) => car.Model_Name));
      } else {
        setCarBlackModels([]);
      }
    } catch (error) {
      console.error("Error fetching car models", error);
      setCarBlackModels([]);
    }
  };

  const fetchWhitelist = async () => {
    const whitelistRes = await SellerWhitelist.getWhitelist();
    if (whitelistRes?.data?.success) {
      setWhiteList(whitelistRes.data.whitelist);
      toast.success(whitelistRes?.data?.message);
    } else {
      toast.error(whitelistRes?.data?.message);
    }
  };

  const fetchBlacklist = async () => {
    const blackListRes = await SellerBlacklist.getBlacklist();
    if (blackListRes?.data?.success) {
      setBlackList(blackListRes.data.blacklist);
      toast.success(blackListRes?.data?.message);
    } else {
      toast.error(blackListRes?.data?.message);
    }
  };

  useEffect(() => {
    fetchWhitelist();
    fetchBlacklist();
    fetchCarMakes();
  }, []);

  useEffect(() => {
    if (triggerFetch) {
      fetchBlacklist();
      setTriggerFetch(false);
    }
  }, [triggerFetch]);

  useEffect(() => {
    if (triggerFetch) {
      fetchWhitelist();
      setTriggerFetch(false);
    }
  }, [triggerFetch]);

  useEffect(() => {
    if (selectedWhiteMake) {
      fetchWhiteCarModels();
    } else {
      setCarWhiteModels([]);
    }
  }, [selectedWhiteMake]);

  useEffect(() => {
    if (selectedBlackMake) {
      fetchBlackCarModels();
    } else {
      setCarBlackModels([]);
    }
  }, [selectedBlackMake]);

  const handleAddItem = async (listType: "whitelist" | "blacklist") => {
    const make = listType === "whitelist" ? selectedWhiteMake : selectedBlackMake;
    const model = listType === "whitelist" ? selectedWhiteModel : selectedBlackModel;

    if (make && model) {
      const newItem: CarItem = { make, model, id: String(Date.now()) };

      if (listType === "whitelist") {
        const addToWhitelistRes = await SellerWhitelist.addToWhitelist(make, model);
        if (addToWhitelistRes.data.success) {
          setWhiteList([...whiteList, newItem]);
          toast.success(addToWhitelistRes.data.message);
          setTriggerFetch(true);
        } else {
          toast.error(addToWhitelistRes.data.message);
        }
      } else if (listType === "blacklist") {
        const addToBlacklistRes = await SellerBlacklist.addToBlacklist(make, model);
        if (addToBlacklistRes.data.success) {
          setBlackList([...blackList, newItem]);
          toast.success(addToBlacklistRes.data.message);
          setTriggerFetch(true);
        } else {
          toast.error(addToBlacklistRes.data.message);
        }
      }

      if (listType === "whitelist") {
        setSelectedWhiteMake("");
        setSelectedWhiteModel("");
      } else {
        setSelectedBlackMake("");
        setSelectedBlackModel("");
      }
    }
  };

  const handleDeleteItem = async (
    itemId: string,
    listType: "whitelist" | "blacklist",
    index: number
  ) => {
    if (listType === "whitelist") {
      const deleteRes = await SellerWhitelist.deleteFromWhitelist(itemId);
      if (deleteRes.data.success) {
        setWhiteList(whiteList.filter((_, i) => i !== index));
        toast.success(deleteRes.data.message);
      } else {
        toast.error(deleteRes.data.message);
      }
    } else {
      const deleteRes = await SellerBlacklist.deleteFromBlacklist(itemId);
      if (deleteRes.data.success) {
        setBlackList(blackList.filter((_, i) => i !== index));
        toast.success(deleteRes.data.message);
      } else {
        toast.error(deleteRes.data.message);
      }
    }
  };

  return (
    <div className="stack-container" style={{ marginBottom: 100 }}>
      {/* White List Section */}
      <div className="space" style={{}}>
        <h2 className="text-center">White List</h2>
        <p className="text-center text-shadow space">Cars you have</p>

        <div className="row-container space wide" style={{ gap: 10 }}>
          {carMakes.length && (
            <Dropdown
              items={carMakes}
              title="Select car make"
              onSelect={setSelectedWhiteMake}
            />
          )}
          <Dropdown
            items={carWhiteModels}
            title="Select car model"
            onSelect={setSelectedWhiteModel}
          />
        </div>

        <button
          onClick={() => handleAddItem("whitelist")}
          disabled={!selectedWhiteMake || !selectedWhiteModel}
          className="button wide"
        >
          SAVE
        </button>

        {whiteList.map((item, index) => (
          <div
            className="row-container"
            key={index}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              padding: "5px 0",
              // minWidth: 300,
              maxWidth: 300
            }}
          >
            <small className="bold">
              {item.make} - {item.model}
            </small>
            <button
              className="space"
              onClick={() => {
                const userConfirmed = window.confirm("Are you sure you want to delete this item?");
                if (userConfirmed) {
                  handleDeleteItem(item.id, "whitelist", index);
                } else {
                }
              }}
              style={{ marginLeft: "auto", backgroundColor: "#EEE", border: "none" }}
            >
              <DeleteIcon />
            </button>
          </div>
        ))}
      </div>

      {/* Black List Section */}
      <div className="space">
        <h2 className="text-center">Black List</h2>
        <p className="text-center text-shadow space">Cars you don't have</p>

        <div className="row-container space wide" style={{ gap: 5 }}>
          {carMakes.length && (
            <Dropdown
              items={carMakes}
              title="Select car make"
              onSelect={setSelectedBlackMake}
            />
          )}
          <Dropdown
            items={carBlackModels}
            title="Select car model"
            onSelect={setSelectedBlackModel}
          />
        </div>

        <button
          onClick={() => handleAddItem("blacklist")}
          disabled={!selectedBlackMake || !selectedBlackModel}
          className="button wide"
        >
          SAVE
        </button>

        {blackList.map((item, index) => (
          <div
            className="row-container"
            key={index}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              padding: "5px 0",
              // minWidth: 300,
              maxWidth: 300
            }}
          >
            <small className="bold">
              {item.make} - {item.model}
            </small>
            <button
              className="space"
              onClick={() => {
                const userConfirmed = window.confirm("Are you sure you want to delete this item?");
                if (userConfirmed) {
                  handleDeleteItem(item.id, "blacklist", index);
                } else {
                }
              }}
              style={{ marginLeft: "auto", backgroundColor: "#EEE", border: "none" }}
            >
              <DeleteIcon />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SellerConfigScreen;
