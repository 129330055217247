import apiClient from './apiClient';

const buyerAuthBaseRoute = "users";
const sellerAuthBaseRoute = "sellers";

interface BuyerInfoResponse {
    data: {
        user: {
            email: string;
            firstName: string;
            lastName: string;
            phone: string;
        };
    };
}

function isError(error: unknown): error is Error {
    return (error as Error).message !== undefined;
}

export const Account = {
    // Buyer
    getBuyerInfo: async (): Promise<any> => {
        try {
            return await apiClient.get(`${buyerAuthBaseRoute}/info`);
        } catch (error) {
            return error;
        }
    },
    updateBuyerInfo: async (firstName: string, lastName: string, phone?: string): Promise<any> => {
        try {
            return await apiClient.put(`${buyerAuthBaseRoute}/update`, { firstName, lastName, phone });
        } catch (error) {
            return error;
        }
    },
    changeBuyerPassword: async (currentPassword: string, newPassword: string): Promise<any> => {
        try {
            return await apiClient.put(`${buyerAuthBaseRoute}/change-password`, { currentPassword, newPassword });
        } catch (error) {
            return error;
        }
    },
    disableBuyerAccount: async (): Promise<any> => {
        try {
            return await apiClient.put(`${buyerAuthBaseRoute}/deactivate`);
        } catch (error) {
            return error;
        }
    },
    deleteBuyerAccount: async (buyerPassword: string): Promise<any> => {
        try {
            return await apiClient.delete(`${buyerAuthBaseRoute}/delete`, { data: { password: buyerPassword } });
        } catch (error) {
            return error;
        }
    },

    // Seller
    getSellerInfo: async (): Promise<any> => {
        try {
            return await apiClient.get(`${sellerAuthBaseRoute}/info`);
        } catch (error) {
            return error;
        }
    },
    updateSellerInfo: async (businessName: string, phone?: string, businessAddress?: Object): Promise<any> => {
        try {
            return await apiClient.put(`${sellerAuthBaseRoute}/update`, { businessName, phone, businessAddress });
        } catch (error) {
            return error;
        }
    },
    updateSellerProfileImage: async (profileImage?: string | null): Promise<any> => {
        try {
            return await apiClient.put(`${sellerAuthBaseRoute}/profile-image`, { profileImage });
        } catch (error) {
            return error;
        }
    },
    changeSellerPassword: async (currentPassword: string, newPassword: string): Promise<any> => {
        try {
            return await apiClient.put(`${sellerAuthBaseRoute}/change-password`, { currentPassword, newPassword });
        } catch (error) {
            return error;
        }
    },
    disableSellerAccount: async (): Promise<any> => {
        try {
            return await apiClient.put(`${sellerAuthBaseRoute}/deactivate`);
        } catch (error) {
            return error;
        }
    },
    deleteSellerAccount: async (sellerPassword: string): Promise<any> => {
        try {
            return await apiClient.delete(`${sellerAuthBaseRoute}/delete`, { data: { password: sellerPassword } });
        } catch (error) {
            return error;
        }
    }
}