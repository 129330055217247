// FieldsConstraints.ts
interface FieldConstraint {
    minLength?: number;
    maxLength?: number;
    required?: boolean;
    pattern?: RegExp;
}

const FieldsConstraints = {
    user: {
        firstName: {
            minLength: 1,
            maxLength: 30,
            required: true,
        },
        lastName: {
            minLength: 1,
            maxLength: 30,
            required: true,
        },
        email: {
            required: true,
            pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        },
        password: {
            minLength: 8,
            maxLength: 20,
            required: true,
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,20}$/,
        },
        phone: {
            required: false,
            pattern: /^\+?[1-9]\d{1,14}$/, // International phone number pattern (E.164)
        },
        gender: {
            required: false,
            pattern: /^(male|female|other)$/i,
        },
        dateOfBirth: {
            required: false,
            pattern: /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d\d$/, // MM/DD/YYYY format
        },
    },
};

export default FieldsConstraints;
