import React, { useRef, useState } from 'react';
import Modal from '../Modal';
import Input from '../../ui/inputs/Input';
import FieldsConstraints from '../../../constants/FieldConstraints';
import { useSellerAuth } from '../../../contexts/seller/SellerAuthContext';
import { Autocomplete } from "@react-google-maps/api";
import '../../../global.css';
import { toast } from 'react-toastify';

interface SellerSignupModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSignup: (name: string, email: string, password: string) => void;
  onSuccess: (sellerName: string, email: string, password: string) => void;
  onFail?: (errorCode: string, errorMessage: string, email: string) => void;
}

const SellerSignupModal: React.FC<SellerSignupModalProps> = ({ isOpen, onClose, onSignup, onSuccess, onFail }) => {
  const [sellerName, setSellerName] = useState('');
  const [email, setEmail] = useState('');
  const [profilePicture, setProfilePicture] = useState<string | null>(null);
  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const { signupSeller, loading } = useSellerAuth();

  const [addressInput, setAddressInput] = useState("");
  const [isAddressValid, setIsAddressValid] = useState(false);
  const autocompleteRef: any = useRef(null);

  const [address, setAddress] = useState({
    streetAddress: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
    lng: 0,
    lat: 0
  });

  const extractAddressDetails = (place: any) => {
    let address = {
      streetAddress: "",
      city: "",
      state: "",
      zipcode: "",
      country: "",
      lng: 0,
      lat: 0
    };

    if (place.address_components) {
      place.address_components.forEach((component: any) => {
        const types = component.types;
        if (types.includes("street_number")) {
          address.streetAddress = component.long_name + " ";
        }
        if (types.includes("route")) {
          address.streetAddress += component.long_name;
        }
        if (types.includes("locality")) {
          address.city = component.long_name;
        }
        if (types.includes("administrative_area_level_1")) {
          address.state = component.long_name;
        }
        if (types.includes("postal_code")) {
          address.zipcode = component.long_name;
        }
        if (types.includes("country")) {
          address.country = component.long_name;
        }
      });
    }
    return address;
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    if (name === "businessAddress") {
      setAddress((prevState) => ({
        ...prevState,
        streetAddress: value,
      }));
      // setAddressInput(value);
      // setIsAddressValid(false);
      return;
    }
  };

  const onPlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();

      if (place && place.address_components) {
        const structuredAddress = extractAddressDetails(place);

        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();

        const addressWithGeometry = {
          ...structuredAddress,
          lat,
          lng,
        };
        setAddress(addressWithGeometry);
        setAddressInput(place.formatted_address || structuredAddress.streetAddress);
        setIsAddressValid(true);
      }
    }
  };


  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result as string;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          if (ctx) {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            const jpegBase64 = canvas.toDataURL("image/jpeg", 0.9);
            const base64Data = jpegBase64.split(',')[1];
            setProfilePicture(base64Data);
          }
        };
      };

      // Read the file as a data URL (base64 string)
      reader.readAsDataURL(file);
    }
  }

  const validateSignupFields = () => {
    const userConstraints = FieldsConstraints.user;
    let errors: string[] = [];

    if (!sellerName) {
      errors.push("Seller name is required.");
    }
    if (sellerName.length < 2) {
      errors.push(`Seller name must be at least ${userConstraints.firstName.minLength} characters.`);
    }
    if (sellerName.length > userConstraints.firstName.maxLength) {
      errors.push(`Seller name must be no more than ${userConstraints.firstName.maxLength} characters.`);
    }

    const emailRegex = userConstraints.email.pattern;
    if (!email.match(emailRegex)) {
      errors.push("Please enter a valid email address.");
    }

    if (!password.length) {
      errors.push("Password must not be empty");
    }

    if (password !== confirmedPassword) {
      errors.push("Passwords must match");
    }

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,16}$/;
    if (!passwordRegex.test(password)) {
      errors.push("Password must be 8-16 characters long, contain an uppercase letter, a lowercase letter, a number, and a special character");
    }

    return errors;
  };

  const handleSignup = async () => {
    const errors = validateSignupFields();

    if (errors.length > 0) {
      // return;
    }

    if (!address.zipcode) {
      toast.error("Address is not valid");
      return;
    }

    let signupRes;

    signupRes = await signupSeller(sellerName, email, password, profilePicture, address);

    if (signupRes?.success) {
      // alert("Signup successful!");
      toast.success("Signup successful!");
      setSellerName('');
      setEmail('');
      setPassword('');
      setConfirmedPassword('');
      setProfilePicture(null);
      onSuccess(sellerName, email, password);
    } else {
      onFail?.(signupRes.errorCode ?? "Unknown error", signupRes.message ?? "Unknown error", email);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="stack-container white">
        <h2 className='text-shadow'>Sign Up</h2>
        <Input
          placeholder="Enter your business name"
          value={sellerName}
          onChangeText={setSellerName}
        />
        <Input
          placeholder="Enter your email"
          value={email}
          onChangeText={setEmail}
        />
        <div style={{ marginTop: 0, marginBottom: 14 }}>
          <Autocomplete
            onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
            onPlaceChanged={onPlaceChanged}
          >
            <input
              type="text"
              name="businessAddress"
              placeholder="Enter business address"
              value={address.streetAddress}
              onChange={handleInputChange}
              required
              style={{ width: 300, height: 14, borderRadius: 6, borderWidth: 1, borderColor: "#DDD", padding: 14, fontSize: 16, outline: "none" }}
            />
          </Autocomplete>
        </div>
        <Input
          placeholder="Enter your password"
          value={password}
          onChangeText={setPassword}
          secureTextEntry={true}
        />
        <Input
          placeholder="Confirm password"
          value={confirmedPassword}
          onChangeText={setConfirmedPassword}
          secureTextEntry={true}
        />

        <div className="wide" style={{ marginTop: 20, marginBottom: 20, fontSize: 18 }}>
          <label htmlFor="file-upload" className="upload-button">
            {profilePicture ? (
              <img src={`data:image/jpeg;base64,${profilePicture}`} alt="Profile" className="profile-image" style={{ width: 100, height: 100 }} />
            ) : (
              "Upload Profile Picture"
            )}
          </label>
          <input
            type="file"
            id="file-upload"
            onChange={handleFileChange}
            style={{ display: "none" }}
            accept="image/*"
          />
        </div>
        <button className="button wide" onClick={handleSignup}>SIGN UP</button>
      </div>
    </Modal>
  );
};

export default SellerSignupModal;
