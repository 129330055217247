// import React, { useState } from 'react';
// import { useBuyerAuth } from '../../contexts/buyer/BuyerAuthContext';
// import '../../global.css';
// import { useNavigate } from 'react-router-dom';
// import Tabs from '../tabs/Tabs';
// import BuyerAccountScreen from '../../pages/buyer/BuyerAccountDetails';
// import RequestCar from '../../pages/buyer/RequestCar';
// import MyRequests from '../../pages/buyer/MyRequests';
// import BuyerHome from '../../pages/buyer/BuyerHome';

// const BuyerHeader: React.FC = () => {
//     const [activeTab, setActiveTab] = useState("buyer-home");
//     const { userData: buyerData, isLoggedIn: isBuyerLoggedIn, logoutBuyer } = useBuyerAuth();
//     const navigate = useNavigate();

//     let tabs = [
//         { label: "HOME", value: "buyer-home"},
//         { label: 'MY REQUESTS', value: 'my-requests' },
//         { label: 'REQUEST CAR', value: 'request-car' },
//         { label: 'ACCOUNT', value: 'account-details' },
//         // { label: "LOG OUT", value: "log-out" }
//     ];

//     const headerStyle: React.CSSProperties = {
//         position: 'sticky',
//         top: 0,
//         backgroundColor: '#EEE',
//         color: '#222',
//         padding: '10px 0',
//         width: '100%',
//         zIndex: 1000,
//         textAlign: 'center',
//     };

//     return (
//         <>
//             <header className="row-container" style={{ backgroundColor: "#FFF", marginTop: 20, marginBottom: 20 }}>
//                 {/* <p className="text-left" style={{ marginLeft: 5 }}>{userData?.firstName ? userData.firstName : userData?.email}</p> */}
//                 <div>
//                     <Tabs
//                         tabs={tabs}
//                         onTabClick={async (tab: any) => {
//                             setActiveTab(tab);
//                             if (tab === "log-out") {
//                                 await logoutBuyer();
//                                 navigate("/");
//                             }
//                         }} />
//                 </div>

//             </header>
//             <div>
//                 {activeTab === "buyer-home" && <BuyerHome />}
//                 {activeTab === 'account-details' && <BuyerAccountScreen />}
//                 {activeTab === 'request-car' && <RequestCar />}
//                 {activeTab === 'my-requests' && <MyRequests />}
//             </div>
//         </>
//     );
// };

// export default BuyerHeader;



import React, { useState } from 'react';
import { useBuyerAuth } from '../../contexts/buyer/BuyerAuthContext';
import '../../global.css';
import { useNavigate } from 'react-router-dom';
import Tabs from '../tabs/Tabs';
import BuyerAccountScreen from '../../pages/buyer/BuyerAccountDetails';
import RequestCar from '../../pages/buyer/RequestCar';
import MyRequests from '../../pages/buyer/MyRequests';
import BuyerHome from '../../pages/buyer/BuyerHome';

const BuyerHeader: React.FC = () => {
    const [activeTab, setActiveTab] = useState("buyer-home");
    const { userData: buyerData, isLoggedIn: isBuyerLoggedIn, logoutBuyer } = useBuyerAuth();
    const navigate = useNavigate();

    let tabs = [
        { label: "HOME", value: "buyer-home" },
        { label: 'MY REQUESTS', value: 'my-requests' },
        { label: 'REQUEST CAR', value: 'request-car' },
        { label: 'ACCOUNT', value: 'account-details' },
        // { label: "LOG OUT", value: "log-out" }
    ];

    return (
        <>
            <header
                className="row-container"
                style={{
                    backgroundColor: "#222",
                    // marginTop: 20,
                    marginBottom: 1,
                    borderBottom: "1px solid #EEE",
                    padding: "10px 0",
                    width: "100%",
                    textAlign: "center",
                    height: 60
                }}
            >
                <div>
                    <Tabs
                        tabs={tabs}
                        onTabClick={async (tab: any) => {
                            setActiveTab(tab);
                            if (tab === "log-out") {
                                await logoutBuyer();
                                navigate("/");
                            }
                        }} />
                </div>
            </header>

            <div>
                {activeTab === "buyer-home" && <BuyerHome />}
                {activeTab === 'account-details' && <BuyerAccountScreen />}
                {activeTab === 'request-car' && <RequestCar />}
                {activeTab === 'my-requests' && <MyRequests />}
            </div>
        </>
    );
};

export default BuyerHeader;
