enum ErrorCodes {
    INVALID_INPUT = 'INVALID_INPUT',
    ACCOUNT_NOT_FOUND = 'ACCOUNT_NOT_FOUND',
    INVALID_OR_EXPIRED_CODE = 'INVALID_OR_EXPIRED_CODE',
    INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
    INVALID_EMAIL_FORMAT = 'INVALID_EMAIL_FORMAT',
    INVALID_PASSWORD_FORMAT = 'INVALID_PASSWORD_FORMAT',
    ACCOUNT_ALREADY_EXIST = 'ACCOUNT_ALREADY_EXIST',
    FAILED_PROFILE_UPLOAD = 'FAILED_PROFILE_UPLOAD',
    ACCOUNT_NOT_VERIFIED = 'ACCOUNT_NOT_VERIFIED',
    EMAIL_AND_PASSWORD_REQUIRED = 'EMAIL_AND_PASSWORD_REQUIRED',
    INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
    ACCOUNT_INACTIVE = 'ACCOUNT_INACTIVE',
    INVALID_OR_EXPIRED_VERIFICATION_CODE = 'INVALID_OR_EXPIRED_VERIFICATION_CODE',
    AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED',
    ACCOUNT_BLOCKED = 'ACCOUNT_BLOCKED',
    REQUEST_NOT_FOUND = 'REQUEST_NOT_FOUND',
    ALREADY_EXISTS_IN_WHITELIST = 'ALREADY_EXISTS_IN_WHITELIST',
    ALREADY_EXISTS_IN_BLACKLIST = 'ALREADY_EXISTS_IN_BLACKLIST',
    DUPLICATE_ENTRY = 'DUPLICATE_ENTRY',
}

export default ErrorCodes;