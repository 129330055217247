import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBuyerAuth } from './contexts/buyer/BuyerAuthContext';
import { useSellerAuth } from './contexts/seller/SellerAuthContext';
import "./global.css";
import Footer from './components/footer/Footer';

function App() {
  const [showBuyer, setShowBuyer] = useState(false);
  const [showSeller, setShowSeller] = useState(false);

  const { isLoggedIn: isBuyerLoggedIn } = useBuyerAuth();
  const { isLoggedIn: isSellerLoggedIn } = useSellerAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (isBuyerLoggedIn) {
      setShowBuyer(true);
      setShowSeller(false);
      navigate('/buyer');
    } else if (isSellerLoggedIn) {
      setShowSeller(true);
      setShowBuyer(false);
      navigate('/seller');
    } else {
      // If neither is logged in, reset both states
      setShowBuyer(false);
      setShowSeller(false);
    }
  }, [isBuyerLoggedIn, isSellerLoggedIn]);

  return (
    <>
      {/* <div className="text-center" style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        margin: "0 auto",
        width: 400,
        height: 170,
        backgroundColor: "#111",
        padding: 20,
        zIndex: 100,
      }}>
        <div>
          <h1 style={{ color: "#eee" }}>What is Car Enki?</h1>
          <p style={{ color: "#eee" }}>For Users: Submit a car request, and dealers will contact you with offers via the app chat.</p>
          <p style={{ color: "#eee" }}>For Dealers: Get instant notifications of requests and send offers through the app chat.</p>
        </div>
      </div> */}
      <div className='stack-container'
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          textAlign: "center",
        }}
      >
        {(!showBuyer && !showSeller) && (
          <div style={{ backgroundColor: "#FFF", display: "flex", flexDirection: "column", padding: 10, alignItems: "center", justifyContent: "center", borderRadius: 10, minWidth: 300, height: 300, gap: 12 }}>
            <button
              className="button"
              style={{
                marginBottom: "20px",
              }}
              // className='success'
              onClick={() => navigate('/seller')}
            >
              DEALER
            </button>
            <button
              className="button"
              onClick={() => navigate('/buyer')}
            >
              USER
            </button>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
}

export default App;
