// src/contexts/seller/AuthContext.tsx

// TODO: make sure to log in only one type of user
import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { Auth } from '../../api/auth';
import { Account } from '../../api/account';

interface UserData {
  sellerName: string;
  email: string;
  profileImage?: string | null;
  phone: number;
}

interface SellerAuthContextType {
  isLoggedIn: boolean;
  userData: UserData | null;
  loginSeller: (email: string, password: string) => Promise<{ success: boolean, message: string, errorCode?: string }>;
  signupSeller: (sellerName: string, email: string, password: string, profileImage?: string | null, sellerAddress?: Object, phone?: string) => Promise<{ success: boolean, message: string, errorCode?: string }>;
  logoutSeller: () => void;
  updateSellerData: () => Promise<UserData | null>;
  loading: boolean;
  error: string | null;
}

const SellerAuthContext = createContext<SellerAuthContextType>({
  isLoggedIn: false,
  userData: null,
  loginSeller: async () => ({ success: false, message: '', errorCode: undefined }),
  signupSeller: async () => ({ success: false, message: '', errorCode: undefined }),
  logoutSeller: () => { },
  updateSellerData: async () => null,
  loading: false,
  error: null,
});

interface SellerAuthProviderProps {
  children: ReactNode;
}

export const SellerAuthProvider: React.FC<SellerAuthProviderProps> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadUserData = () => {
      try {
        const storedUserData = localStorage.getItem('sellerData');
        const storedIsLoggedIn = localStorage.getItem('isLoggedIn') === 'true';

        if (storedIsLoggedIn && storedUserData) {
          setIsLoggedIn(true);
          setUserData(JSON.parse(storedUserData));
        }
      } catch (e) {
        console.error('Error loading user data from localStorage:', e);
      }
    };

    loadUserData();
  }, []);

  const loginSeller = async (email: string, password: string): Promise<{ success: boolean, message: string, errorCode?: string }> => {
    setLoading(true);
    try {
      const loginRes = await Auth.loginSeller(email, password);

      if (loginRes?.data?.success) {
        const user = loginRes.data?.user;
        const token = loginRes.data?.token;

        const sellerData = { ...user, token }; // we only need these from login response

        setUserData(loginRes.data?.user);
        setIsLoggedIn(true);
        setError(null);

        localStorage.setItem('sellerData', JSON.stringify(sellerData));
        localStorage.setItem('userToken', token);
        localStorage.setItem('isLoggedIn', 'true');

        return loginRes?.data;
      }
      setError('Login failed');
      return loginRes?.data;
    } catch (err: any) {
      setError('Login failed: ' + err.message);
      return { success: false, message: err.message, errorCode: err.message }
    } finally {
      setLoading(false);
    }
  };

  const signupSeller = async (
    sellerName: string,
    email: string,
    password: string,
    profileImage?: string | null,
    sellerAddress?: Object,
    phone?: string,
  ): Promise<{ success: boolean; message: string, errorCode?: string }> => {
    setLoading(true);
    try {
      const signupRes = await Auth.signupSeller(sellerName, email, password, profileImage, sellerAddress, phone);
      if (signupRes.data.success) {
        setError(null);
        return { success: true, message: signupRes.data.message };
      }
      setError('Signup failed');
      return { success: false, message: signupRes.data.message, errorCode: signupRes.data.errorCode };
    } catch (err: any) {
      setError('Signup failed: ' + err.message);
      return { success: false, message: err.message, errorCode: err.message };
    } finally {
      setLoading(false);
    }
  };

  const logoutSeller = async (): Promise<Boolean> => {
    setIsLoggedIn(false);
    setUserData(null);
    localStorage.removeItem('sellerData');
    localStorage.removeItem('userToken');
    localStorage.setItem('isLoggedIn', 'false');
    return true;
  };

  const updateSellerData = async () => {
    setLoading(true);

    try {
      // Fetch the most up-to-date user info from the server (or wherever it’s stored)
      const sellerInfoRes = await Account.getSellerInfo();

      if (sellerInfoRes?.data?.success) {
        const latestSellerData = sellerInfoRes.data.user;

        // Update the user data with the fresh data from the server
        setUserData(latestSellerData);
        localStorage.setItem('sellerData', JSON.stringify(latestSellerData));

        return latestSellerData;
      } else {
        setError('Failed to fetch the latest user data.');
        return null;
      }
    } catch (err: any) {
      setError('Failed to update seller data: ' + err.message);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return (
    <SellerAuthContext.Provider value={{ isLoggedIn, userData, loginSeller, signupSeller, logoutSeller, updateSellerData, loading, error }}>
      {children}
    </SellerAuthContext.Provider>
  );
};

export const useSellerAuth = (): SellerAuthContextType => useContext(SellerAuthContext);
