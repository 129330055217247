// src/api/auth.tsx
import apiClient from "./apiClient";

const buyerAuthBaseRoute = "users";
const sellerAuthBaseRoute = "sellers";

export const Auth = {
  token: null as string | null,
  user: null as any,

  loginBuyer: async (email: string, password: string): Promise<any> => {
    try {
      return await apiClient.post(`${buyerAuthBaseRoute}/signin`, { email, password });
    } catch (error) {
      return error;
    }
  },
  signupBuyer: async (email: string, password: string): Promise<any> => {
    try {
      return await apiClient.post(`${buyerAuthBaseRoute}/signup`, { email, password, firstName: "fahad", lastName: "haidari", phone: "7200000", profileImage: null });
    } catch (error) {
      throw error;
    }
  },
  loginSeller: async (email: string, password: string): Promise<any> => {
    try {
      return await apiClient.post(`${sellerAuthBaseRoute}/signin`, { email, password });
    } catch (error) {
      return error;
    }
  },
  signupSeller: async (businessName: string, email: string, password: string, profileImage?: string | null, businessAddress?: Object, phone: string = ""): Promise<any> => {
    try {
      return await apiClient.post(`${sellerAuthBaseRoute}/signup`, { businessName, email, password, profileImage, businessAddress, phone });
    } catch (error) {
      console.log("error now", error);
      throw error;
    }
  },
};