import React, { useEffect, useState } from "react";
import Input from "../../components/ui/inputs/Input";
import Textarea from "../../components/ui/inputs/TextArea";
import "../../global.css";
import { CarRequest } from "../../api/userCarRequest";
import { toast } from "react-toastify";
import Dropdown from "../../components/ui/dropdown/Dropdown";
import { CarData } from "../../api/carData";
import { useBuyerAuth } from "../../contexts/buyer/BuyerAuthContext";
import getColorPalette from "../../utils/ColorPalettes";

const BuyerRequestCarScreen = () => {
  const [carMakes, setCarMakes] = useState<string[]>([]);
  const [carModels, setCarModels] = useState<string[]>([]);
  const [carMake, setCarMake] = useState<string>("");
  const [carModel, setCarModel] = useState<string>("");
  const [zipCode, setZipCode] = useState<string>("");
  const [isLoadingModels, setIsLoadingModels] = useState<boolean>(false);
  const [minYear, setMinYear] = useState<string>("");
  const [maxYear, setMaxYear] = useState<string>("");
  const [minPrice, setMinPrice] = useState<string>("");
  const [maxPrice, setMaxPrice] = useState<string>("");
  const [isCleanTitle, setIsCleanTitle] = useState<boolean>(true);
  const [isSalvage, setIsSalvage] = useState<boolean>(false);
  const [color, setColor] = useState<string>("");
  const [notes, setNotes] = useState<string>("");
  const [otherModel, setOtherModel] = useState<boolean>(false);
  const [requestMade, setRequestMade] = useState<boolean>(false);

  const { userData, updateUserData } = useBuyerAuth();

  const fetchCarMakes = async (): Promise<void> => {
    try {
      const carMakesRes = await CarData.getMakes();
      if (carMakesRes?.data?.makes) {
        setCarMakes(carMakesRes.data.makes.map((car: any) => car.MakeName));
      } else {
        setCarMakes([]);
      }
    } catch (error) {
      console.error("Error fetching car makes", error);
      setCarMakes([]);
    }
  };

  const fetchCarModels = async (): Promise<void> => {
    try {
      setIsLoadingModels(true);
      const carModelsRes = await CarData.getModels(carMake);
      if (carModelsRes?.data?.success) {
        setCarModels(carModelsRes.data.models.map((car: any) => car.Model_Name));
      } else {
        setCarModels([]);
      }
    } catch (error) {
      console.error("Error fetching car models", error);
      setCarModels([]);
    } finally {
      setIsLoadingModels(false);
    }
  };

  useEffect(() => {
    fetchCarMakes();
  }, []);

  useEffect(() => {
    if (carMake) {
      fetchCarModels();
    } else {
      setCarModels([]);
    }
  }, [carMake]);

  useEffect(() => {
    if (!isCleanTitle && !isSalvage) {
      toast.error("Clean or salvage or both");
      setIsCleanTitle(true);
    }
  }, [isCleanTitle, isSalvage]);

  const handleSelectedOption = (option: string) => {
    setCarMake(option);
  };

  const handleRequest = async () => {
    if (minYear > maxYear) {
      toast.error("Min year must be less than or equal max year");
      return;
    }
    if (minPrice > maxPrice) {
      toast.error("Min price must be less than or equal max price");
      return;
    }
    const carRequestRes = await CarRequest.addCarRequest(
      carMake,
      carModel,
      minYear,
      maxYear,
      minPrice,
      maxPrice,
      isCleanTitle,
      isSalvage,
      color,
      zipCode,
      notes,
    );
    if (carRequestRes.data.success) {
      toast.success(carRequestRes.data.message);
      updateUserData();
    } else {
      toast.error(carRequestRes.data.message);
    }
    setRequestMade(true);
  };

  return (
    <div className="stack-container">
      <div className="space">
        <p className="text-center text-shadow">Pick car make and model</p>

        <div className="space row-container" style={{ gap: 5 }}>
          {/* Dropdown for car makes */}

          <Dropdown
            items={carMakes}
            title="Select car make"
            onSelect={handleSelectedOption}
          />


          {/* Conditional dropdown for car models */}
          {/* {isLoadingModels ? (
            <div>Loading models...</div>
          ) : carModels.length > 0 ? (
            <Dropdown items={carModels} title="Select car model" onSelect={setCarModel} />
          ) : (
            <Input
              placeholder="Car model"
              value={carModel}
              style={{ marginTop: 18 }}
              onChangeText={setCarModel}
            />
          )} */}
          <Dropdown items={carModels} title="Select car model" onSelect={setCarModel} />
        </div>
      </div>

      {/* Other inputs */}
      {/* <div>
        <button
          onClick={() => setOtherModel(!otherModel)}
          className="button small-button clean"
          style={{ color: "#4488FF" }}
        >
          {!otherModel ? "Model not in the list?" : "Hide"}
        </button>
      </div> */}

      <div className="row-container space">
        <Input
          value={zipCode}
          placeholder={userData?.location?.zipCode ? "Zip code: " + userData?.location?.zipCode : "Zip code"}
          onChangeText={setZipCode}
          style={{ marginRight: 5, marginBottom: 20, width: "100%" }}
        />
      </div>
      {/* Year and price inputs */}
      <div className="row-container space">
        <Input
          value={minYear}
          placeholder="Min year"
          onChangeText={setMinYear}
          style={{ marginRight: 5, marginBottom: 20, width: "24%" }}
        />
        <Input
          value={maxYear}
          placeholder="Max year"
          onChangeText={setMaxYear}
          style={{ marginLeft: 5, width: "24%" }}
        />
      </div>

      <div className="row-container space">
        <Input
          value={minPrice}
          placeholder="Min price"
          onChangeText={setMinPrice}
          style={{ marginRight: 5, width: "24%" }}
        />
        <Input
          value={maxPrice}
          placeholder="Max price"
          onChangeText={setMaxPrice}
          style={{ marginLeft: 5, width: "24%" }}
        />
      </div>

      <div className="space">
        <button
          onClick={() => setIsCleanTitle(!isCleanTitle)}
          className=""
          style={{ gap: 5, backgroundColor: isCleanTitle ? getColorPalette("success").backgroundColor: "#EEE", color: isCleanTitle ? "#FFF" : "#222", marginRight: 10, border: isCleanTitle ? "none" : "solid 1px #AAA", padding: 5, borderRadius: 6 }}
        >
          Clean Title
        </button>
        <button
          onClick={() => setIsSalvage(!isSalvage)}
          className=""
          style={{ gap: 5, backgroundColor: isSalvage ? getColorPalette("success").backgroundColor : "#EEE", color: isSalvage ? "#FFF" : "#222", marginLeft: 10, border: isSalvage ? "none" : "solid 1px #AAA", padding: 5, borderRadius: 6 }}
        >
          Salvage Title
        </button>
      </div>

      <div className="space">
        <Input
          value={color}
          placeholder="Color preference"
          onChangeText={setColor}
        />
      </div>

      <div className="space">
        <Textarea
          placeholder="Additional notes ..."
          value={notes}
          onChangeText={setNotes}
          maxLength={200}
          autoFocus={true}
        />
      </div>

      <button
        className="button"
        onClick={handleRequest}
        style={{ backgroundColor: carMake.length === 0 ? "#777" : "#111", marginBottom: 200 }}
        disabled={carMake.length === 0}
      >
        REQUEST CAR
      </button>
    </div>
  );
};

export default BuyerRequestCarScreen;
