import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { BuyerAuthProvider } from './contexts/buyer/BuyerAuthContext';
import { SellerAuthProvider } from './contexts/seller/SellerAuthContext';
import BuyerRoot from './pages/buyer/BuyerRoot';
import SellerRoot from './pages/seller/SellerRoot';
import { Bounce, ToastContainer } from 'react-toastify';
import './index.css';
import { LoadScript } from '@react-google-maps/api';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY || "";
const libraries: any = ["places"];

root.render(
  <React.StrictMode>
    <LoadScript googleMapsApiKey={GOOGLE_API_KEY} libraries={libraries}>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
      <BuyerAuthProvider>
        <SellerAuthProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<App />} />
              <Route path="/buyer" element={<BuyerRoot />} />
              <Route path="/seller" element={<SellerRoot />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-of-service" element={<TermsOfService />} />
            </Routes>
          </BrowserRouter>
        </SellerAuthProvider>
      </BuyerAuthProvider>
    </LoadScript>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
