import React, { useState } from 'react';
import Modal from '../Modal';
import Input from '../../ui/inputs/Input';
import '../../../global.css';
import { useBuyerAuth } from '../../../contexts/buyer/BuyerAuthContext';
import ErrorCodes from '../../../api/errorCodes';
import FieldsConstraints from '../../../constants/FieldConstraints';
import { toast } from 'react-toastify';

interface BuyerSignupModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSignup: (name: string, email: string, password: string) => void;
  onSuccess: (email: string, password: string) => void;
  onFail?: (errorCode: string, errorMessage: string, email: string) => void;
}

const BuyerSignupModal: React.FC<BuyerSignupModalProps> = ({ isOpen, onClose, onSignup, onSuccess, onFail, }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const { signupBuyer, loading } = useBuyerAuth();


  const validateSignupFields = () => {
    const userConstraints = FieldsConstraints.user;
    let errors: string[] = [];

    const emailRegex = userConstraints.email.pattern;
    if (!email.match(emailRegex)) {
      errors.push("Please enter a valid email address.");
    }

    if (!password.length) {
      errors.push("Password must not be empty");
    }

    if (password !== confirmedPassword) {
      errors.push("Passwords must match");
    }

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,16}$/;
    if (!passwordRegex.test(password)) {
      errors.push("Password must be 8-16 characters long, contain an uppercase letter, a lowercase letter, a number, and a special character");
    }

    return errors;
  };

  const handleSignup = async () => {
    const errors = validateSignupFields();

    if (errors.length > 0) {
      // setErrorMessages(errors);
      // setShowErrorReport(true);
      return;
    } else {
      // setShowErrorReport(false);
    }

    // Proceed with signup if there are no errors
    const signupRes = await signupBuyer(email, password);
    console.log("signup res", signupRes);

    if (signupRes.success) {
      console.log("signed up");
      // alert(signupRes.message);
      toast.success(signupRes.message);
      setEmail('');
      setPassword('');
      setConfirmedPassword('');
      await onSuccess(email, password);
    } else {
      if (signupRes.errorCode === ErrorCodes.ACCOUNT_NOT_VERIFIED) {
        onFail?.(ErrorCodes.ACCOUNT_NOT_VERIFIED, signupRes.message, email);
      } else {
        onFail?.(signupRes.errorCode ?? "Unknown error", signupRes.message ?? "Unknown error", email);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="stack-container white">

        <h2 className='text-shadow'>Sign Up</h2>
        <Input
          placeholder="Email"
          value={email}
          onChangeText={setEmail}
        />
        <Input
          placeholder="Password"
          value={password}
          onChangeText={setPassword}
          secureTextEntry={true}
        />
        <Input
          placeholder="Confirm password"
          value={confirmedPassword}
          onChangeText={setConfirmedPassword}
          secureTextEntry={true}
        />
        <button
          className="button wide"
          onClick={handleSignup}
        >
          SIGN UP
        </button>
      </div>
    </Modal>
  );
};

export default BuyerSignupModal;
