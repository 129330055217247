import apiClient from './apiClient';

const sellerWhitelistBaseRoute = "/car-whitelist";

export const SellerWhitelist = {
    getWhitelist: async (): Promise<any> => {
        try {
            return await apiClient.get(`${sellerWhitelistBaseRoute}`);
        } catch (error) {
            return error;
        }
    },
    addToWhitelist: async (make: string, model: string): Promise<any> => {
        try {
            return await apiClient.post(`${sellerWhitelistBaseRoute}`, { make, model });
        } catch (error) {
            return error;
        }
    },
    deleteFromWhitelist: async (id: string): Promise<any> => {
        try {
            return await apiClient.delete(`${sellerWhitelistBaseRoute}/${id}`);
        } catch (error) {
            return error;
        }
    },
};
