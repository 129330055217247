// src/api/verificationCode.tsx
import apiClient from './apiClient';

const passwordResetBaseRoute = "passwordReset";

export const PasswordResetAPI = {
    token: null as string | null,
    user: null as any,

    requestPassword: async (email: string, accountType: string) => {
        try {
            return await apiClient.post(`${passwordResetBaseRoute}/request-password`, { email, accountType });
        } catch (error) {
            console.error("Error in requesting password", error);
            throw error;
        }
    },

    generatePassword: async (email: string, accountType: string, code: string) => {
        try {
            const response = await apiClient.put(`${passwordResetBaseRoute}/generate-password`, { email, accountType, code });
            // Check if the response is an AxiosResponse or a custom object
            if ('data' in response) {
                return response.data;
            } else {
                return response;
            }
        } catch (error: any) {
            console.error("Error in generating password", error);
            return { success: false, message: error.message };
        }
    },
};
