import apiClient from './apiClient';

const carRequestBaseRoute = "car-requests";

export const CarRequest = {
    getCarsRequests: async (): Promise<any> => {
        try {
            return await apiClient.get(`${carRequestBaseRoute}`);
        } catch (error) {
            return error;
        }
    },
    getCarRequest: async (id: string): Promise<any> => {
        try {
            return await apiClient.get(`${carRequestBaseRoute}/${id}`);
        } catch (error) {
            return error;
        }
    },
    addCarRequest: async (make: string, model: string, minYear?: string, maxYear?: string, minPrice?: string, maxPrice?: string, isClean?: boolean, isSalvage?: boolean, color?: string, zipCode?: string, additionalNotes?: string): Promise<any> => {
        try {
            return await apiClient.post(`${carRequestBaseRoute}`, { make, model, minYear, maxYear, minPrice, maxPrice, isClean, isSalvage, color, zipCode, additionalNotes });
        } catch (error) {
            return error;
        }
    },
    deleteCarRequest: async (id: string): Promise<any> => {
        try {
            return await apiClient.put(`${carRequestBaseRoute}/${id}/soft-delete`);
        } catch (error) {
            return error;
        }
    },
};
