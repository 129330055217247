import React, { useState } from 'react';
import Modal from './Modal';
import { VerificationCodeAPI } from '../../api/verificationCode';
import '../../global.css';
import Input from '../ui/inputs/Input';
import { toast } from 'react-toastify';

interface VerifyCodeProps {
    visible: boolean;
    onSuccess: (code: string, success: boolean) => void;
    onFail?: (errorMessage: string) => void;
    onClose: () => void;
    email: string;
    accountType: string;
}

const VerifyCode: React.FC<VerifyCodeProps> = ({ visible, onSuccess, onFail, onClose, email, accountType }) => {
    const [internalCode, setInternalCode] = useState<string>('');
    const [isValid, setIsValid] = useState(true);
    const [isVerifying, setIsVerifying] = useState(false);

    const handleChange = (text: string) => {
        if (/^\d{0,6}$/.test(text)) {
            setInternalCode(text);
        }
    };

    const handleSubmit = async () => {
        if (internalCode.length === 6) {
            setIsValid(true);
            setIsVerifying(true);
            const verifyCodeRes = await VerificationCodeAPI.verifyCode(email, internalCode, "email", accountType);

            if (verifyCodeRes.success) {
                // alert(verifyCodeRes.message);
                toast.success(verifyCodeRes.message);
                onSuccess(internalCode, verifyCodeRes.success);
            } else {
                onFail?.(verifyCodeRes.message ?? "Unknown error");
                // alert(verifyCodeRes.message ?? "Error verifying code");
                toast.error(verifyCodeRes.message ?? "Error verifying code");
            }
            setIsVerifying(false);
        } else {
            setIsValid(false);
        }
    };

    return (
        <Modal isOpen={visible} onClose={onClose}>
            <div className="stack-container" style={{ marginTop: 10, marginBottom: 10 }}>
                <h2 className="">Enter 6-Digit Verification Code</h2>

                <Input
                    placeholder="Enter code"
                    value={internalCode}
                    onChangeText={handleChange}
                    maxLength={6}
                />

                {!isValid && <p className="errorText">Please enter a valid 6-digit code.</p>}

                <button
                    title="VERIFY"
                    onClick={handleSubmit}
                    disabled={isVerifying}
                    className="button wide"
                    style={{
                        cursor: isVerifying ? 'not-allowed' : 'pointer',
                    }}
                >
                    VERIFY
                </button>
            </div>
        </Modal>
    );
};

export default VerifyCode;
