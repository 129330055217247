import React, { useState, useEffect } from "react";
import '../../global.css';
import { CarRequest } from "../../api/userCarRequest";
import { toast } from "react-toastify";
import DeleteIcon from "../../components/icons/DeleteIcon";

interface CarRequestType {
  id: string;
  userId?: string;
  model?: string;
  make: string;
  minYear?: number | null;
  maxYear?: number | null;
  color?: string | null;
  minMileage?: number | null;
  maxMileage?: number | null;
  carCondition?: string | null;
  minPrice?: number | null;
  maxPrice?: number | null;
  isClean?: boolean | false;
  isSalvage?: boolean | false;
  additionalNotes?: string | null;
}


const BuyerMyCarsScreen: React.FC = () => {
  const [userRequests, setUserRequests] = useState<CarRequestType[]>([]);
  const fetchUserRequests = async (): Promise<CarRequestType[]> => {
    const carRequestsRes = await CarRequest.getCarsRequests();
    // console.log("car requests", carRequestsRes.data.carRequests);
    if (carRequestsRes?.data?.success) {
      toast.success(carRequestsRes.data.message);
      setUserRequests(carRequestsRes?.data?.carRequests);
      return carRequestsRes.data.carRequests;
    } else {
      // alert(carRequestsRes.data.message);
      toast.error(carRequestsRes?.data?.message ? carRequestsRes.data.message : "Error getting car requests");
      return [];
    }
  };

  useEffect(() => {
    fetchUserRequests();
  }, []);

  const handleDelete = async (id: string) => {
    const deleteCarRequestRes = await CarRequest.deleteCarRequest(id);
    if (deleteCarRequestRes.data.success) {
      const updatedRequests = userRequests.filter((request) => request.id !== id);
      setUserRequests(updatedRequests);
      toast.success(deleteCarRequestRes.data.message);
    } else {
      // alert(deleteCarRequestRes.data.message);
      toast.error(deleteCarRequestRes.data.message);
    }
  };

  return (
    <div className="stack-container" style={{ marginTop: 40 }}>
      <div className="">
        {(!userRequests || userRequests?.length) === 0 ? (
          <p className="text-center">No car requests found</p>
        ) : (
          userRequests.map((request) => (
            <div key={request.id} style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              // alignItems: 'center',
              marginBottom: 20,
              padding: '10px',
              backgroundColor: "#FFF",
              borderRadius: 10,
              width: 400,
              gap: 20,
              marginTop: 10,
            }}>
              <div
                style={{
                  flex: 1
                }}
              >
                <h3 className="bold text-center" style={{ backgroundColor: "#FFF", borderRadius: 6, width: "fit-content", margin: "0 auto", padding: 5 }}>
                  {request.make && request.make} - {request.model && request.model}
                </h3>
              </div>

              {/* min year */}
              <div
                style={{
                  flex: 1
                }}
              >
                <label className="bold" style={{ display: 'inline' }}>
                  Min Year:
                  <p className="text-shadow" style={{ display: 'inline', margin: 0, marginLeft: 5 }}>
                    {request.minYear || " -"}
                  </p>
                </label>
              </div>

              {/* max year */}
              <div
                style={{
                  flex: 1
                }}
              >
                <label className="bold" style={{ display: 'inline' }}>
                  Max Year:
                  <p className="text-shadow" style={{ display: 'inline', margin: 0, marginLeft: 5 }}>
                    {request.maxYear || " -"}
                  </p>
                </label>
              </div>

              {/* min price */}
              <div
                style={{
                  flex: 1
                }}
              >
                <label className="bold" style={{ display: 'inline' }}>
                  Min Price:
                  <p className="text-shadow" style={{ display: 'inline', margin: 0, marginLeft: 5 }}>
                    {request.minPrice ? "$" + request.minPrice : " -"}
                  </p>
                </label>
              </div>

              {/* max price */}
              <div
                style={{
                  flex: 1
                }}
              >
                <label className="bold" style={{ display: 'inline' }}>
                  Max Price:
                  <p className="text-shadow" style={{ display: 'inline', margin: 0, marginLeft: 5 }}>
                    {request.maxPrice ? "$" + request.maxPrice : " -"}
                  </p>
                </label>
              </div>

              {/* clearn title */}
              <div
                style={{
                  flex: 1
                }}
              >
                <label className="bold" style={{ display: 'inline' }}>
                  Clean Title:
                  <p className="text-shadow" style={{ display: 'inline', margin: 0, marginLeft: 5 }}>
                    {request.isClean ? " Yes" : " No"}
                  </p>
                </label>
              </div>

              {/* salvage title */}
              <div
                style={{
                  flex: 1
                }}
              >
                <label className="bold" style={{ display: 'inline' }}>
                  Salvage Title:
                  <p className="text-shadow" style={{ display: 'inline', margin: 0, marginLeft: 5 }}>
                    {request.isSalvage ? " Yes" : " No"}
                  </p>
                </label>
              </div>

              {/* color */}
              <div
                style={{
                  flex: 1
                }}
              >
                <label className="bold" style={{ display: 'inline' }}>
                  Color Preference:
                  <p className="text-shadow" style={{ display: 'inline', margin: 0, marginLeft: 5, color: request.color ? request.color.trim() : "#333" }}>
                    {request.color || " -"}
                  </p>
                </label>
              </div>


              <button
                onClick={() => handleDelete(request.id)}
                className="button small-button clean text-danger bold"
              // style={{ backgroundColor: "#FFF" }}
              >
                <DeleteIcon />
              </button>
            </div>
          ))
        )}
      </div>
      {/* A hacky way to leave space at the bottom, rather than set marginBottom for all divs */}
      <div style={{ marginBottom: 50 }}></div> 
    </div>
  );
};

export default BuyerMyCarsScreen;
