import React, { useState } from 'react';
import Modal from '../Modal';
import Input from '../../ui/inputs/Input';
import '../../../global.css';
import ErrorCodes from '../../../api/errorCodes';
import { useSellerAuth } from '../../../contexts/seller/SellerAuthContext';
import ForgotPasswordModal from '../ForgotPasswordModal';

interface SellerLoginModalProps {
  isOpen: boolean;
  onClose: () => void;
  onLogin: (email: string, password: string) => void;
  onFail?: (errorCode: string, errorMessage: string, email: string) => void;
}

const SellerLoginModal: React.FC<SellerLoginModalProps> = ({ isOpen, onClose, onLogin, onFail }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const { loginSeller, loading } = useSellerAuth();

  const handleLogin = async () => {
    if (email && password) {
      // setEmail('');
      // setPassword('');
      const loginRes = await loginSeller(email, password);

      if (loginRes.success) {
        console.log("logged in");
        onLogin(email, password);

      } else {
        if (loginRes.errorCode === ErrorCodes.ACCOUNT_NOT_VERIFIED) {
          onFail?.(ErrorCodes.ACCOUNT_NOT_VERIFIED, loginRes.message, email);
        } else {
          onFail?.(loginRes.errorCode ?? "Unknown error", loginRes.message ?? "Unknown error", email);
        }
      }
    } else {
      alert('Validation Error, Please fill in both email and password.');
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <div className="stack-container white">
          <h2 className='text-shadow'>Log In</h2>

          <Input
            placeholder="Email"
            value={email}
            onChangeText={setEmail}
          />

          <Input
            placeholder="Password"
            value={password}
            onChangeText={setPassword}
            secureTextEntry={true}
          />

          <button
            title="Login"
            onClick={handleLogin}
            className='button wide'
          >
            LOG IN
          </button>
        </div>
        <div>
          <button
            onClick={() => setShowForgotPassword(true)}
            className='button space'
            style={{ backgroundColor: "#FFF", color: "#111" }}
          >
            FORGOT PASSWORD
          </button>
          <ForgotPasswordModal
            accountType='seller'
            isOpen={showForgotPassword}
            onClose={() => setShowForgotPassword(false)}
            onResetPassword={() => {

            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default SellerLoginModal;
