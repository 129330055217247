import apiClient from './apiClient';

const sellerBlacklistBaseRoute = "/car-blacklist";

export const SellerBlacklist = {
    getBlacklist: async (): Promise<any> => {
        try {
            return await apiClient.get(`${sellerBlacklistBaseRoute}`);
        } catch (error) {
            return error;
        }
    },
    addToBlacklist: async (make: string, model: string): Promise<any> => {
        try {
            return await apiClient.post(`${sellerBlacklistBaseRoute}`, { make, model });
        } catch (error) {
            return error;
        }
    },
    deleteFromBlacklist: async (id: string): Promise<any> => {
        try {
            return await apiClient.delete(`${sellerBlacklistBaseRoute}/${id}`);
        } catch (error) {
            return error;
        }
    },
};
