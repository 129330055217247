import React from 'react';

const TransitionScreen = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <h1 className="text-center" style={{ color: "#777" }}>
        Car Enki
      </h1>
    </div>
  );
};

export default TransitionScreen;
