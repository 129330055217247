// src/components/SellerRoot.tsx
import React, { useEffect, useLayoutEffect, useState } from 'react';
import SellerSignupModal from '../../components/modals/seller/SellerSignupModal';
import SellerLoginModal from '../../components/modals/seller/SellerLoginModal';
import { VerificationCodeAPI } from '../../api/verificationCode';
import SendVerificationCodeModal from '../../components/modals/SendVerificationCode';
import ErrorCodes from '../../api/errorCodes';
import VerifyCode from '../../components/modals/VerifyCode';
import { useSellerAuth } from '../../contexts/seller/SellerAuthContext';
import '../../global.css';
import { toast } from 'react-toastify';
import TransitionScreen from '../../components/TransitionScreen';
import { useBuyerAuth } from '../../contexts/buyer/BuyerAuthContext';
import ContentSlider from '../../components/slider/ContentSlider';
import SellerHeader from '../../components/header/SellerHeader';
import Footer from '../../components/footer/Footer';
import InvitationModal from '../../components/modals/seller/InvitationModal';

const SellerRoot: React.FC = () => {
  const [showInvitationModal, setShowInvitationModal] = useState(false);
  const [invitationVerified, setInvitationVerified] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSendVerificationCode, setShowSendVerificationCode] = useState(false);
  const [showVerifyCode, setShowVerifyCode] = useState(false);
  const [email, setEmail] = useState("");
  const [userReady, setUserReady] = useState(false);
  const [showTransition, setShowTransition] = useState(false);
  const { isLoggedIn, logoutSeller, userData } = useSellerAuth();
  const { isLoggedIn: isBuyerLoggedIn, logoutBuyer } = useBuyerAuth();

  const forceLogoutBuyer = async function () {
    await logoutBuyer();
  }

  useLayoutEffect(() => {
    if (isBuyerLoggedIn) {
      forceLogoutBuyer();
    }
  }, [isBuyerLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      setShowTransition(true);
      toast.success("Login successful!", {
        onClose: () => {
          setUserReady(true);
          setShowTransition(false);
        },
        onClick: () => {
          setUserReady(true);
          setShowTransition(false);
        },
      });
    }
  }, [isLoggedIn]);

  const handleLoginClick = () => {
    setShowLoginModal(true);
  };

  const handleSignupClick = () => {
    if (!invitationVerified) {
      setShowInvitationModal(true);
      return;
    }
    setShowSignupModal(true);
  };

  const handleModalClose = () => {
    setShowSignupModal(false);
    setShowLoginModal(false);
  };

  const handleSignup = (name: string, email: string, password: string) => {
    // alert(`Sign Up successful with Name: ${name}, Email: ${email}`);
    setShowSignupModal(false);
  };

  const handleLogin = (email: string, password: string) => {
    // alert(`Login successful with Email: ${email}`);
    setShowLoginModal(false);
  };

  if (showTransition) {
    return <TransitionScreen />
  }

  if (isLoggedIn && userReady) {
    return (
      <>
        <SellerHeader />
        <Footer />
      </>
    );
  };

  return (
    <div
      className="stack-container"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
      }}
    >
      <header className="">
        {/* <h1 className='text-center'>Welcome to the Seller Dashboard</h1> */}
      </header>
      {/* <div style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: "#FFF",
        padding: 20,
        zIndex: 100,
      }}>
        <ContentSlider>
          <div style={{ background: '#FFF', width: "100%", height: "200px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <h1>Welcome to the dealer dashboard</h1>
          </div>
          <div style={{ background: '#FFF', width: "100%", height: "200px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <h2>Leads to your hand</h2>
          </div>
          <div style={{ background: '#FFF', width: "100%", height: "200px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <h2>Reach out to them</h2>
          </div>
          <div style={{ background: '#FFF', width: "100%", height: "200px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <h2>Make a deal</h2>
          </div>
        </ContentSlider>
      </div> */}
      <div style={{ backgroundColor: "#FFF", display: "flex", flexDirection: "column", padding: 10, alignItems: "center", justifyContent: "center", borderRadius: 10, minWidth: 300, height: 300 }}>
        <button
          title="Login"
          onClick={handleLoginClick}
          className='button space'
        >
          LOG IN
        </button>
        <button
          onClick={handleSignupClick}
          className='button space'
        >
          SIGN UP
        </button>
      </div>

      {/* Seller Signup Modal */}
      <SellerSignupModal
        isOpen={showSignupModal}
        onClose={handleModalClose}
        onSignup={handleSignup}
        onSuccess={async (sellerName, email) => {
          setEmail(email);
          const sendEmailRes = await VerificationCodeAPI.sendVerificationCode(email, "email", "seller");

          if (sendEmailRes.data.success) {
            // alert(sendEmailRes.data.message);
            toast.success(sendEmailRes.data.message, {
              onClose: () => {
                setShowVerifyCode(true);
              },
              onClick: () => {
                setShowVerifyCode(true);
              },
            });
          } else {
            // alert(sendEmailRes.data.message);
            toast.error(sendEmailRes.data.message);
          }
        }}
        onFail={async (errorCode: string, errorMessage: string, email) => {
          if (errorCode === ErrorCodes.ACCOUNT_NOT_VERIFIED) {
            setEmail(email)
            // alert(errorMessage);
            toast.error(errorMessage);
            const sendEmailRes = await VerificationCodeAPI.sendVerificationCode(email, "email", "user");

            if (sendEmailRes.data.success) {
              // alert(sendEmailRes.data.message);
              toast.success(sendEmailRes.data.message);
              setShowVerifyCode(true);
            } else {
              // alert(sendEmailRes.data.message);
              toast.error(sendEmailRes.data.message);

            }
          } else {
            setEmail(email);
            // alert(errorMessage);
            toast.error(errorMessage);
          }
        }}
      />

      {/* Seller Login Modal */}
      <SellerLoginModal
        isOpen={showLoginModal}
        onClose={handleModalClose}
        onLogin={handleLogin}
        onFail={async (errorCode: string, errorMessage: string, email) => {
          if (errorCode === ErrorCodes.ACCOUNT_NOT_VERIFIED) {
            setEmail(email)
            // alert(errorMessage);
            toast.error(errorMessage);
            const sendEmailRes = await VerificationCodeAPI.sendVerificationCode(email, "email", "seller");

            if (sendEmailRes.data.success) {
              // alert(sendEmailRes.data.message);
              toast.success(sendEmailRes.data.message);
              setShowVerifyCode(true);
            } else {
              // alert(sendEmailRes.data.message);
              toast.error(sendEmailRes.data.message);
            }
          } else {
            setEmail(email);
            // alert(errorMessage);
            toast.error(errorMessage);
          }
        }}
      />

      <InvitationModal 
        visible={showInvitationModal}
        onClose={() => setShowInvitationModal(false)}
        onFail={() => {
          alert("Error verifiying invitation code");
        }}
        onSuccess={() => {
          setInvitationVerified(true);
          setShowInvitationModal(false);
          setShowSignupModal(true);
        }}  
      />

      <SendVerificationCodeModal
        visible={showSendVerificationCode}
        onClose={() => {
          setShowSendVerificationCode(false)
        }}
        onSend={async () => {
          const sendVerificationCodeRes = await VerificationCodeAPI.sendVerificationCode(email, "email", "seller");
          if (sendVerificationCodeRes?.data?.success) {
            toast.success(sendVerificationCodeRes.data.message);
            setShowSendVerificationCode(false);
            setShowVerifyCode(true);
          } else {
            toast.error(sendVerificationCodeRes.data.message);
          }
        }}
        userEmail={email}
      />
      <VerifyCode
        visible={showVerifyCode}
        onSuccess={() => {
          setShowVerifyCode(false);
          setShowLoginModal(true);
        }}
        onFail={(errorMessage: string) => {
          console.error(errorMessage);
        }}
        onClose={() => {
          setShowVerifyCode(false);
        }}
        email={email}
        accountType="seller"
      />
    </div>
  );
};

export default SellerRoot;
