import React from 'react';
import '../../global.css';

const Footer = () => {
    return (
        <div
            style={{
                position: "fixed",
                bottom: 0,
                left: "50%",
                width: "100%",
                transform: "translateX(-50%)",
                backgroundColor: "#222",
                color: "#333",
                padding: "10px 20px",
                borderTop: "1px solid #EEE",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    gap: 20
                }}
            >
                <button 
                    style={{ backgroundColor: "#222", color: "#FFF", border: "none" }}
                    onClick={() => {
                        alert("Please, contact us at: support@carenki.com");
                    }}
                >
                    Contact us
                </button>
                <a href="/privacy-policy" style={footerLinkStyle}>Privacy Policy</a>
                <a href="/terms-of-service" style={footerLinkStyle}>Terms of Service</a>
            </div>
        </div>
    );
};

const footerLinkStyle: React.CSSProperties = {
    textDecoration: 'none',
    color: '#FFF',
    cursor: 'pointer',
    fontSize: '14px',
};

export default Footer;
