// src/api/verificationCode.tsx
import apiClient from './apiClient';

const verificationBaseRoute = "verificationCode";

export const VerificationCodeAPI = {
    code: null as string | null,
    user: null as any,

    sendVerificationCode: async (email: string, method: string, accountType: string) => {
        try {
            return await apiClient.post(`${verificationBaseRoute}/send-verification`, { email, method, accountType });
        } catch (error) {
            throw error;
        }
    },

    verifyCode: async (email: string, code: string, method: string, accountType: string) => {
        try {
            const verifyCodeRes = await apiClient.post(`${verificationBaseRoute}/verify-code`, { email, code, method, accountType });
            if (verifyCodeRes.data.success) {
                return { success: true, message: verifyCodeRes.data.message };
            }

            return { success: false, message: verifyCodeRes.data.message };
        } catch (err: any) {
            console.error("Error in verifying code", err);
            return { success: false, message: err.message };
        }
    },
};