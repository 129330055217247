import React, { useState } from 'react';
import Modal from './Modal';
import Input from '../ui/inputs/Input';

interface SendVerificationCodeModalProps {
    visible: boolean;
    onClose: () => void;
    onSend: () => void;
    userEmail: string;
}

const SendVerificationCodeModal: React.FC<SendVerificationCodeModalProps> = ({
    visible,
    onClose,
    onSend,
    userEmail,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [email, setEmail] = useState(userEmail);
    const [codeSent, setCodeSent] = useState(false);
    const [popupVisible, setPopupVisible] = useState(false);

    const handleSendCode = () => {
        onSend();
        setCodeSent(true);
    };

    return (
        <Modal
            isOpen={visible} onClose={onClose}
        >
            <div className="modalOverlay">
                <div className="modalContainer">
                    <Input
                        style={{ width: '100%', marginBottom: 15 }}
                        placeholder="Enter your email ..."
                        value={userEmail}
                        onChangeText={setEmail}
                        disabled={true}
                        inputStyle={{
                            backgroundColor: '#EEE',
                            borderRadius: 12,
                            textAlign: 'center',
                        }}
                    />

                    <button
                        className="button"
                        onClick={() => setPopupVisible(true)}
                    >
                        SEND VERIFICATION CODE
                    </button>

                    {codeSent && (
                        <p style={{ marginTop: 20, color: 'green', fontSize: 16 }}>
                            Verification code sent successfully!
                        </p>
                    )}

                    {/* <Popup
                        visible={popupVisible}
                        message="Sending verification code ..."
                        messageType="success"
                        onClose={() => setPopupVisible(false)}
                        onAnimationComplete={() => {
                            setTimeout(() => {
                                handleSendCode();
                            }, 1000);
                        }}
                    /> */}
                </div>
                <div className="modalFooter" />
            </div>
        </Modal>
    );
};

export default SendVerificationCodeModal;
