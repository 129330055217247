// src/components/modals/Modal.tsx
import React from 'react';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  const modalOverlayStyles: React.CSSProperties = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    // backgroundColor: "#FFF",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 999,
  };

  const modalContentStyles: React.CSSProperties = {
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    backgroundColor: '#FFF',
    padding: '20px',
    borderRadius: '8px',
    position: 'relative',
    minWidth: '300px',
  };

  const modalCloseStyles: React.CSSProperties = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'transparent',
    border: 'none',
    fontSize: '18px',
    cursor: 'pointer',
  };

  return (
    <div
      className="fade-in-anim"
      style={modalOverlayStyles}
      onClick={onClose}
    >
      <div
        style={modalContentStyles}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className="modal-close"
          style={modalCloseStyles}
          onClick={onClose}
          onMouseEnter={(e) => e.currentTarget.style.color = 'red'}
          onMouseLeave={(e) => e.currentTarget.style.color = ''}
        >
          X
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
