// src/components/BuyerRoot.tsx
import React, { useEffect, useLayoutEffect, useState } from 'react';
import BuyerSignupModal from '../../components/modals/buyer/BuyerSignupModal';
import BuyerLoginModal from '../../components/modals/buyer/BuyerLoginModal';
import ErrorCodes from '../../api/errorCodes';
import { VerificationCodeAPI } from '../../api/verificationCode';
import SendVerificationCodeModal from '../../components/modals/SendVerificationCode';
import VerifyCode from '../../components/modals/VerifyCode';
import { useBuyerAuth } from '../../contexts/buyer/BuyerAuthContext';
import '../../global.css';
import { toast } from 'react-toastify';
import TransitionScreen from '../../components/TransitionScreen';
import { useSellerAuth } from '../../contexts/seller/SellerAuthContext';
import ContentSlider from '../../components/slider/ContentSlider';
import BuyerHeader from '../../components/header/BuyerHeader';
import Footer from '../../components/footer/Footer';

const BuyerRoot: React.FC = () => {
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSendVerificationCode, setShowSendVerificationCode] = useState(false);
  const [showVerifyCode, setShowVerifyCode] = useState(false);
  const [email, setEmail] = useState("");
  const [userReady, setUserReady] = useState(false);
  const [showTransition, setShowTransition] = useState(false);
  const { isLoggedIn, logoutBuyer, userData } = useBuyerAuth();
  const { isLoggedIn: isSellerLoggedIn, logoutSeller } = useSellerAuth();

  const forceLogoutSeller = async function () {
    await logoutSeller();
  }

  useLayoutEffect(() => {
    if (isSellerLoggedIn) {
      forceLogoutSeller();
    }
  }, [isSellerLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      setShowTransition(true);
      toast.success("Login successful!", {
        onClose: () => {
          setUserReady(true);
          setShowTransition(false);
        },
        onClick: () => {
          setUserReady(true);
          setShowTransition(false);
        },
      });
    }
  }, [isLoggedIn]);

  const handleLoginClick = () => {
    setShowLoginModal(true);
  };

  const handleSignupClick = () => {
    setShowSignupModal(true);
  };

  const handleModalClose = () => {
    setShowSignupModal(false);
    setShowLoginModal(false);
  };

  const handleSignup = (name: string, email: string, password: string) => {
    // alert(`Sign Up successful with Name: ${name}, Email: ${email}`);
    setShowSignupModal(false);
  };

  const handleLogin = (email: string, password: string) => {
    // alert(`Login successful with Email: ${email}`);
    setShowLoginModal(false);
  };

  if (showTransition) {
    return <TransitionScreen />
  }


  if (isLoggedIn && userReady) {
    return (
      <>
        <BuyerHeader />
        <Footer />
      </>
    );
  };

  return (
    <div
      className="stack-container"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
      }}
    >
      {/* <header className="">
        <h1 className='text-center'>Welcome to the Buyer Dashboard</h1>
      </header> */}
      {/* <div style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        color: "#FFF",
        backgroundColor: "#EEE",
        padding: 20,
        zIndex: 100,
        borderRadius: 10
      }}>
        <ContentSlider>
          <div style={{ background: '#222', width: "100%", height: "200px", display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 10 }}>
            <h1>Welcome to the buyer dashboard</h1>
          </div>
          <div style={{ background: '#222', width: "100%", height: "200px", display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 10 }}>
            <h2>Dealers will rach out to you</h2>
          </div>
          <div style={{ background: '#222', width: "100%", height: "200px", display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 10 }}>
            <h2>Through the app</h2>
          </div>
          <div style={{ background: '#222', width: "100%", height: "200px", display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 10 }}>
            <h2>No noise</h2>
          </div>
        </ContentSlider>
      </div> */}
      <div style={{ backgroundColor: "#FFF", display: "flex", flexDirection: "column", padding: 10, alignItems: "center", justifyContent: "center", borderRadius: 10, minWidth: 300, height: 300 }}>
        <button
          onClick={handleLoginClick}
          className='button space'
        >
          LOG IN
        </button>
        <button
          title="Sign Up"
          onClick={handleSignupClick}
          className='button space'
        >
          SIGN UP
        </button>
      </div>

      {/* Buyer Signup Modal */}
      <BuyerSignupModal
        isOpen={showSignupModal}
        onClose={handleModalClose}
        onSignup={handleSignup}
        onSuccess={async (email) => {
          setEmail(email);
          setShowSignupModal(false);
          const sendEmailRes = await VerificationCodeAPI.sendVerificationCode(email, "email", "user");

          if (sendEmailRes.data.success) {
            // alert(sendEmailRes.data.message);
            toast.success(sendEmailRes.data.message, {
              onClose: () => {
                setShowVerifyCode(true);
              },
              onClick: () => {
                setShowVerifyCode(true);
              },
            });
          } else {
            // alert(sendEmailRes.data.message);
            toast.error(sendEmailRes.data.message);
          }
        }}
        onFail={async (errorCode: string, errorMessage: string, email) => {
          if (errorCode === ErrorCodes.ACCOUNT_NOT_VERIFIED) {
            setEmail(email)
            // alert(errorMessage);
            toast.error(errorMessage);
            const sendEmailRes = await VerificationCodeAPI.sendVerificationCode(email, "email", "user");

            if (sendEmailRes.data.success) {
              // alert(sendEmailRes.data.message);
              toast.success(sendEmailRes.data.message);
              setShowVerifyCode(true);
            } else {
              // alert(sendEmailRes.data.message);
              toast.error(sendEmailRes.data.message);
            }
          } else {
            setEmail(email);
            // alert(errorMessage);
            toast.error(errorMessage);
          }
        }}
      />

      {/* Buyer Login Modal */}
      <BuyerLoginModal
        isOpen={showLoginModal}
        onClose={handleModalClose}
        onLogin={handleLogin}
        onFail={async (errorCode: string, errorMessage: string, email) => {
          if (errorCode === ErrorCodes.ACCOUNT_NOT_VERIFIED) {
            setEmail(email)
            // alert(errorMessage);
            toast.error(errorMessage);
            const sendEmailRes = await VerificationCodeAPI.sendVerificationCode(email, "email", "user");

            if (sendEmailRes.data.success) {
              // alert(sendEmailRes.data.message);
              toast.success(sendEmailRes.data.message);
              setShowVerifyCode(true);
            } else {
              // alert(sendEmailRes.data.message);
              toast.error(sendEmailRes.data.message);
            }
          } else {
            setEmail(email);
            // alert(errorMessage);
            toast.error(errorMessage);
          }
        }}
      />

      <SendVerificationCodeModal
        visible={showSendVerificationCode}
        onClose={() => {
          setShowSendVerificationCode(false)
        }}
        onSend={async () => {
          const sendVerificationCodeRes = await VerificationCodeAPI.sendVerificationCode(email, "email", "user");
          if (sendVerificationCodeRes?.data?.success) {
            toast.success(sendVerificationCodeRes.data.message);
            setShowSendVerificationCode(false);
            setShowVerifyCode(true);
          } else {
            toast.error(sendVerificationCodeRes.data.message);
          }
        }}
        userEmail={email}
      />
      <VerifyCode
        visible={showVerifyCode}
        onSuccess={() => {
          setShowVerifyCode(false);
          setShowLoginModal(true);
        }}
        onFail={(errorMessage: string) => {
          console.error(errorMessage);
        }}
        onClose={() => {
          setShowVerifyCode(false);
        }}
        email={email}
        accountType="user"
      />
    </div>
  );
};

export default BuyerRoot;
