// src/contexts/buyer/AuthContext.tsx

import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import { Auth } from '../../api/auth';
import { Account } from '../../api/account';

interface UserData {
  firstName: string;
  lastName: string;
  email: string;
  profilePicture?: string | null;
  phone: number;
  gender: string;
  dateOfBirth: string;
  token: string;
  location: {
    city: string,
    state: string,
    country: string,
    zipCode: string
  };
}

interface BuyerAuthContextType {
  isLoggedIn: boolean;
  userData: UserData | null;
  loginBuyer: (email: string, password: string) => Promise<{ success: boolean, message: string, errorCode?: string }>;
  signupBuyer: (email: string, password: string) => Promise<{ success: boolean, message: string, errorCode?: string }>;
  logoutBuyer: () => void;
  updateUserData: () => Promise<UserData | null>;
  loading: boolean;
  error: string | null;
}

const BuyerAuthContext = createContext<BuyerAuthContextType>({
  isLoggedIn: false,
  userData: null,
  loginBuyer: async () => ({ success: false, message: '', errorCode: undefined }),
  signupBuyer: async () => ({ success: false, message: '', errorCode: undefined }),
  logoutBuyer: () => { },
  updateUserData: async () => null,
  loading: false,
  error: null,
});

interface BuyerAuthProviderProps {
  children: ReactNode;
}

export const BuyerAuthProvider: React.FC<BuyerAuthProviderProps> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadUserData = () => {
      try {
        const storedUserData = localStorage.getItem('buyerData');
        const storedIsLoggedIn = localStorage.getItem('isLoggedIn') === 'true';

        if (storedIsLoggedIn && storedUserData) {
          setIsLoggedIn(true);
          setUserData(JSON.parse(storedUserData));
        }
      } catch (e) {
        console.error('Error loading user data from localStorage:', e);
      }
    };

    loadUserData();
  }, []);

  const loginBuyer = async (email: string, password: string): Promise<{ success: boolean, message: string, errorCode?: string }> => {
    setLoading(true);

    try {
      const loginRes = await Auth.loginBuyer(email, password);
      if (loginRes?.data?.success) {

        const user = loginRes.data?.user;
        const token = loginRes.data?.token;
        const buyerData = { ...user, token }; // we only need these from login response
        setUserData(buyerData);
        setIsLoggedIn(true);
        setError(null);

        localStorage.setItem('buyerData', JSON.stringify(buyerData));
        localStorage.setItem('userToken', token);
        localStorage.setItem('isLoggedIn', 'true');

        return loginRes?.data;
      }
      setError('Login failed');
      return loginRes?.data;
    } catch (err: any) {
      setError('Login failed: ' + err.message);
      return { success: false, message: err.message, errorCode: err.message }
    } finally {
      setLoading(false);
    }
  };

  const signupBuyer = async (
    email: string,
    password: string,
  ): Promise<{ success: boolean; message: string, errorCode?: string }> => {
    setLoading(true);
    try {
      const signupRes = await Auth.signupBuyer(email, password);
      if (signupRes.data.success) {
        setError(null);
        return { success: true, message: signupRes.data.message };
      }
      setError('Signup failed');
      return { success: false, message: signupRes.data.message, errorCode: signupRes.data.errorCode };
    } catch (err: any) {
      setError('Signup failed: ' + err.message);
      return { success: false, message: err.message, errorCode: err.message };
    } finally {
      setLoading(false);
    }
  };

  const logoutBuyer = async (): Promise<Boolean> => {
    setIsLoggedIn(false);
    setUserData(null);
    localStorage.removeItem('buyerData');
    localStorage.removeItem('userToken');
    localStorage.setItem('isLoggedIn', 'false');
    // make HTTP call to log out user, then return boolean based on the response
    return true;
  };

  const updateUserData = async () => {
    setLoading(true);

    try {
      // Fetch the most up-to-date user info from the server (or wherever it’s stored)
      const buyerInfoRes = await Account.getBuyerInfo();

      if (buyerInfoRes?.data?.success) {
        const latestUserData = buyerInfoRes.data.user;

        // Update the user data with the fresh data from the server
        setUserData(latestUserData);
        localStorage.setItem('buyerData', JSON.stringify(latestUserData));

        return latestUserData;
      } else {
        setError('Failed to fetch the latest user data.');
        return null;
      }
    } catch (err: any) {
      setError('Failed to update user data: ' + err.message);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return (
    <BuyerAuthContext.Provider value={{ isLoggedIn, userData, loginBuyer, signupBuyer, logoutBuyer, updateUserData, loading, error }}>
      {children}
    </BuyerAuthContext.Provider>
  );
};

export const useBuyerAuth = (): BuyerAuthContextType => useContext(BuyerAuthContext);
