import React, { useEffect, useState } from "react";
import { useBuyerAuth } from "../../contexts/buyer/BuyerAuthContext";
import Input from "../../components/ui/inputs/Input";
import { Account } from "../../api/account";
import '../../global.css';
import { toast } from "react-toastify";

const BuyerAccountScreen = () => {
  const [buyerInfo, setBuyerInfo] = useState(null);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [passwordForDeleteAccount, setPasswordForDeleteAccount] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const { logoutBuyer, userData, updateUserData } = useBuyerAuth();

  useEffect(() => {
    async function getBuyerInfo() {
      const buyerInfoRes = await Account.getBuyerInfo();
      let user = null;
      if (buyerInfoRes?.data?.user) {
        user = buyerInfoRes?.data?.user;
        setEmail(user.email);
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setPhone(user.phone);
      }
    }
    getBuyerInfo();
  }, [userData]);

  const handleDisableAccount = async () => {
    if (window.confirm("Are you sure you want to deactivate your account?")) {
      const disableBuyerRes = await Account.disableBuyerAccount();
      if (disableBuyerRes?.data?.success) {
        await logoutBuyer();
      } else {
        console.log("error disabling account", disableBuyerRes?.data.message);
      }
      setIsProcessing(false);
    }
  };

  const handleDeleteAccount = async () => {
    if (!passwordForDeleteAccount.length) {
      // alert("Password is required for this action ...");
      toast.error("Password is required for this action");

      return;
    }

    if (window.confirm("Are you sure you want to delete your account?")) {
      setIsProcessing(true);
      const deleteAccountRes = await Account.deleteBuyerAccount(passwordForDeleteAccount);
      if (deleteAccountRes?.data?.success) {
        await logoutBuyer();
      } else {
        console.log("delete account res", deleteAccountRes?.data?.message);
      }
      setIsProcessing(false);
    }
  };

  return (
    <div className="stack-container" style={{ marginBottom: 50 }}>
      {/* GENERAL INFO SECTION */}
      <div className="space">
        <h2 className="text-center text-shadow">General Info</h2>
        <Input
          placeholder="Email ..."
          value={email}
          disabled
          onChangeText={() => { }}
        />
        <Input
          placeholder="First Name ..."
          value={firstName}
          onChangeText={setFirstName}
        />
        <Input
          placeholder="Last Name ..."
          value={lastName}
          onChangeText={setLastName}
        />
        <Input
          placeholder="Phone ..."
          value={phone}
          onChangeText={setPhone}
        />
        <button
          className="button wide"

          onClick={async () => {
            setIsProcessing(true);
            const updateBuyerInfoRes = await Account.updateBuyerInfo(firstName, lastName, phone);
            if (updateBuyerInfoRes?.data?.success) {
              // alert(updateBuyerInfoRes?.data?.message);
              updateUserData();
              toast.success(updateBuyerInfoRes?.data?.message);
            } else {
              // alert(updateBuyerInfoRes?.data?.message);
              toast.success(updateBuyerInfoRes?.data?.message);
            }
            setIsProcessing(false);
          }}
          disabled={isProcessing}
        >
          SAVE
        </button>
      </div>

      {/* CHANGE PASSWORD SECTION */}
      <div className="space">
        <h2 className="text-center text-shadow">Change Password</h2>
        <Input
          placeholder="Current password"
          value={currentPassword}
          onChangeText={setCurrentPassword}
          secureTextEntry={true}
        />
        <Input
          placeholder="New password"
          value={newPassword}
          onChangeText={setNewPassword}
          secureTextEntry={true}
        />
        <Input
          placeholder="Confirm password"
          value={confirmedPassword}
          onChangeText={setConfirmedPassword}
          secureTextEntry={true}
        />
        <button
          className="button wide"

          onClick={async () => {
            setIsProcessing(true);
            const changePasswordRes = await Account.changeBuyerPassword(currentPassword, newPassword);
            if (changePasswordRes?.data?.success) {
              // alert(changePasswordRes?.data.message);
              toast.success(changePasswordRes?.data?.message);
            } else {
              // alert(changePasswordRes?.data?.message);
              toast.error(changePasswordRes?.data?.message);
            }
            setIsProcessing(false);
          }}
          disabled={isProcessing}
        >
          CHNAGE PASSWORD
        </button>
      </div>

      {/* DISABLE ACCOUNT SECTION */}
      <div className="space">
        <h2 className="text-center text-shadow">Disable Account</h2>
        <button
          onClick={handleDisableAccount}
          disabled={isProcessing}
          className="button wide clean text-warning bold"
        >
          DISABLE MY ACCOUNT
        </button>
      </div>

      {/* DELETE ACCOUNT SECTION */}
      <div className="space">
        <h2 className="text-center text-shadow">Delete Account</h2>
        <Input
          placeholder="Enter password for deletion"
          value={passwordForDeleteAccount}
          onChangeText={setPasswordForDeleteAccount}
          secureTextEntry={true}
        />
        <button
          onClick={handleDeleteAccount}
          disabled={isProcessing}
          className="button wide clean text-danger bold"
        >
          DELETE MY ACCOUNT
        </button>
      </div>

      {/* LOG OUT BUTTON */}
      <div className="space">
        <button
          onClick={async () => {
            await logoutBuyer();
          }}
          className="button clean"
        >
          LOG OUT
        </button>
      </div>
    </div>
  );
};

export default BuyerAccountScreen;
