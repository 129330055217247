import React, { useState } from 'react';

interface Tab {
  label: string;
  value: string;
}

interface TabsProps {
  tabs: Tab[];
  onTabClick: (value: string) => void;
}

const Tabs: React.FC<TabsProps> = ({ tabs, onTabClick }) => {
  const [activeTab, setActiveTab] = useState<string>(tabs[0].value);

  const handleTabChange = (route: string) => {
    setActiveTab(route);
    onTabClick(route);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
      {tabs.map((tab, index) => (
        <button
          key={index}
          onClick={() => handleTabChange(tab.value)}
          style={{
            padding: 8,
            marginLeft: 5,
            marginRight: 5,
            fontSize: '14px',
            fontWeight: 'bold',
            backgroundColor: "#222",
            // borderColor: activeTab === tab.value ? '#AAA' : '#FFF',
            color: activeTab === tab.value ? '#AAA' : '#FFF',
            // border: '1px solid #ccc',
            // border: "1px solid #FFF",
            // borderRadius: 10,
            border: "none",
            cursor: 'pointer',
            // transition: 'background-color 0.3s, transform 0.2s',
            // boxShadow: activeTab === tab.value ? '0 4px 8px rgba(0, 123, 255, 0.4)' : 'none',
          }}
        //   onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
        //   onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};

export default Tabs;
