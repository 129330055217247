import React from 'react';

const PrivacyPolicy: React.FC = () => {
    return (
        <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
            <h1>Privacy Policy</h1>
            <p>
                Welcome to our website. We value your privacy and are committed to protecting your personal data. This Privacy Policy outlines the types of personal information we collect and how we use, store, and protect that information.
            </p>
            <h2>1. Information We Collect</h2>
            <p>
                We collect personal information such as your name, email address, phone number, and payment details when you use our services or sign up for an account.
            </p>
            <h2>2. How We Use Your Information</h2>
            <p>
                We use the information we collect to provide and improve our services, process transactions, and communicate with you regarding updates, promotions, and account-related activities.
            </p>
            <h2>3. Data Security</h2>
            <p>
                We take data security seriously and implement a variety of security measures to ensure your information is protected. However, no method of transmission over the internet is completely secure, and we cannot guarantee the absolute security of your data.
            </p>
            <h2>4. Third-Party Services</h2>
            <p>
                We do not share your personal information with third-party companies unless required by law or for the purpose of providing services on our behalf.
            </p>
            <h2>5. Your Rights</h2>
            <p>
                You have the right to access, modify, or delete your personal information at any time. If you have any concerns about your data, please contact us.
            </p>
            <h2>6. Changes to This Privacy Policy</h2>
            <p>
                We may update this Privacy Policy from time to time. Any changes will be posted on this page with the revised effective date.
            </p>
            <p>If you have any questions about this Privacy Policy, please contact us.</p>
        </div>
    );
};

export default PrivacyPolicy;
